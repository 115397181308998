@import "variable";

.policy-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
  .policy-title {
    margin: 32px 0;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    /* identical to box height, or 175% */
    // text-align: center;
    letter-spacing: 0.04em;
    color: #00c4cc;
  }
  .policy-content {
    max-width: 1200px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
    letter-spacing: 0.04em;
    color: #555555;
    margin-bottom: 60px;
  }
}
