// mixin用のCSS

// ボタン関連
@mixin float-footer {
  position: fixed;
  z-index: 999;
  bottom: 0;
}

@mixin sticky-footer {
  position: sticky;
  z-index: 999;
  bottom: 0;
}

// 画像のトリミング
@mixin object-fit-cover($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
  object-fit: cover;
}

@mixin flex-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@mixin responsive-modal-resize {
  @include mq-min() {
    max-width: 400px;
    left: calc(50% - 200px);
  }
}

// media query
@mixin mq-min($mq: tb) {
  @media #{map-get($mq-min, $mq)} {
    @content;
  }
}

@mixin mq-max($mq: tb) {
  @media #{map-get($mq-max, $mq)} {
    @content;
  }
}

//select style
@mixin progress-select {
  padding: 0 10px;
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 4px;
}

@mixin line-right {
  border-right: 1px solid $color-gray2;
  padding-right: 20px;
  margin-right: 10px;
}

@mixin bottom-triangle {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
