// listページ

input[type="checkbox"] {
  display: none;
}
.checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: auto;
  padding-left: 30px;
}
.checkbox01::before {
  content: "";
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
  border: 1px solid $color-gray;
  border-radius: 2px;
}
.checkbox01::after {
  border-right: 3px solid $color-black;
  border-bottom: 3px solid $color-black;
  content: "";
  display: block;
  height: 9px;
  left: 10px;
  margin-top: -7px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 5px;
}
input[type="checkbox"]:checked + .checkbox01::after {
  opacity: 1;
}

.list-checkbox {
  //width: 5%;
  input[type="checkbox"] {
    display: none;
    &:before {
      background: #fff;
      border: 1px solid #231815;
      content: "";
      display: block;
      height: 16px;
      left: 5px;
      margin-top: -8px;
      position: absolute;
      top: 50%;
      width: 16px;
    }
    &:after {
      border-right: 3px solid #ed7a9c;
      border-bottom: 3px solid #ed7a9c;
      content: "";
      display: block;
      height: 9px;
      left: 10px;
      margin-top: -7px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      width: 5px;
    }
    &:checked + .checked {
      &::after {
        opacity: 1;
      }
    }
  }
}

.list-table {
  width: 100%;
  border-top: 1px solid $color-gray2;
  border-left: 1px solid $color-gray2;
  tr {
    th,
    td {
      padding: 28px 10px;
      font-weight: bold;
      background: $color-table-th-blue;
      border-bottom: 1px solid $color-gray2;
      border-right: 1px solid $color-gray2;
      height: 120px;
    }
    th {
      .small-txt {
        font-size: 13px;
      }
      &.list-table {
      }
    }
    td {
      padding: 26px 10px;
      font-size: 14px;
      font-weight: normal;
      background: $color-white;
      select {
        @include progress-select;
      }
      .line-right {
        @include line-right;
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        border-right: 1px solid #ccc;
        .tag-button {
          margin: 0 4px 4px 0;
        }
        a {
          display: inline;
        }
      }
      // &:last-of-type {
      //   display: flex;
      //   align-items: center;
      // }
      &.list-table-memo {
        display: table-cell;
      }
      .employment-status-tag {
        display: inline-block;
        padding: 4px;
        background: $color-gray2;
        border-radius: 4px;
      }
    }
  }
}

.applicants-message-button {
  display: inline-block;
  padding: 4px;
  background: $color-blue;
  color: $color-white;
  border-radius: 4px;
}

.list-count {
  display: flex;
  li {
    margin-right: 8px;
  }
}

.list-sort {
  margin: 20px 0 75px;
  select {
    @include progress-select;
  }
  .input-txt {
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    border: 1px solid #fff8e5;
  }
}

.applicant-list-sort {
  display: grid;
  grid-template-columns: 100px auto;
  margin-bottom: 20px;
  column-gap: 20px;

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .list-sort-item {
      width: 30%;
      margin-bottom: 12px;
    }
  }
}
.wrapp-input {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
}

.input-txt,
.select-wrapper {
  width: 340px;
  margin-bottom: 0;
  margin-right: 12px;
  select {
    margin-bottom: 0;
  }
}

.job-list,
.applicants-list {
  .job-list-top-btn {
    justify-content: flex-end;
  }
}

// .job-list {
//   tr {
//     th {
//       &:first-of-type {
//         width: 60px;
//       }
//       &:nth-of-type(2) {
//         width: 300px;
//       }
//       &:nth-of-type(3) {
//         width: 200px;
//       }
//       &:nth-of-type(4) {
//         width: 200px;
//       }
//       &:nth-of-type(5) {
//         width: 200px;
//       }
//       &:nth-of-type(6) {
//         width: 130px;
//       }
//       &:last-of-type {
//         width: 196px;
//       }
//     }
//   }
// }

.applicants-list {
  tr {
    th {
      &:first-of-type {
        width: 60px;
      }
      &:nth-of-type(2) {
        width: 130px;
      }
      &:nth-of-type(3) {
        width: 280px;
      }
      &:nth-of-type(4) {
        width: 280px;
      }
      &:nth-of-type(5) {
        width: 220px;
      }
      &:nth-of-type(6) {
        width: 130px;
      }
      &:last-of-type {
        width: 186px;
      }
    }
  }
}

//blank fix UI

.blank {
  min-width: max-content;
  min-height: 167px;
  background-color: #f2fcfd;
}

//checkbox selected item
.checkbox-selected-item {
  position: fixed;
  display: flex;
  bottom: 0;
  padding: 20px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: $color-white;
  p {
    font-size: 14px;
    font-weight: bold;
  }
}

//th sort style
.th-sort {
  position: relative;
  .th-sort-arrow {
    margin-left: 8px;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      width: 0;
      border-bottom: 4.5px solid $color-black;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
    &:after {
      content: "";
      position: absolute;
      top: 14px;
      width: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4.5px solid $color-black;
    }
    &.th-sort-2row {
      &:before {
        top: 15px;
      }
      &:after {
        top: 26px;
      }
    }
  }
}
