@import "variable";

// 企業情報詳細
.company-item {
  width: 100%;
  // max-width: 1456px;
  position: relative;
  background: $color-white;
  padding: 40px;
  border-radius: 16px;
  text-align: left;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .btn-add {
    max-width: 213px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    padding: 8px 40px;
  }
}

.company-item:first-child {
  margin-top: 0;
}

.company-h1-item {
  width: 100%;
  max-width: 1456px;
  margin: 40px auto 0 auto;
  display: flex;
}

.company-h1-on {
  color: $color-blue;
  font-weight: bold;
  font-size: 18px;
  background: $color-bg-blue;
  padding: 20px;
  margin: 0 10px 0 0;
  border-radius: 8px 8px 0 0;
  width: fit-content;
}

.company-h1-off {
  color: $color-black;
  font-size: 18px;
  background: $color-white;
  padding: 20px;
  margin: 0 10px 0 0;
  border-radius: 8px 8px 0 0;
  width: fit-content;

  a {
    color: $color-black;
  }
}

.company-ttl-item {
  display: flex;

  .company-ttl {
    font-size: 16px;
    font-weight: bold;
    width: calc(100% - 260px);
  }

  .company-img {
    width: 100%;
  }

  .company-btn {
    display: flex;
    justify-content: flex-end;
    a {
      cursor: pointer;

      img {
        width: 25px;
      }
    }
  }
}

.company-list {
  margin-top: 20px;

  li {
    border-bottom: 1px solid $color-gray;
    padding: 20px 0;

    &:first-child {
      border-top: 1px solid $color-gray;
    }

    dl {
      display: flex;
      font-size: 14px;

      dt {
        width: 20%;
        display: flex;
        min-width: 230px;
      }

      dd {
        width: 80%;

        .flex-box {
          display: flex;

          .flex-box-ttl {
            width: 20%;
            min-width: 100px;
          }

          .flex-box-txt {
            width: 80%;
          }
        }

        .flex-box:first-child {
          margin-bottom: 10px;
        }

        .btn-blue-small {
          margin-right: 10px;
        }

        p {
          display: flex;
          align-items: center;

          span {
            padding: 8px 10px 8px 0;
          }
        }

        input,
        textarea {
          margin-bottom: 0;
        }

        select {
          width: 100%;
        }

        textarea {
          height: 120px;
        }

        .txt-link-blue {
          color: $color-blue;
          margin-bottom: 10px;
        }

        .img-up-item {
          max-width: 200px;
          position: relative;
          margin-top: 10px;

          .img {
            img {
              width: 100%;
              object-fit: cover;
              max-width: 200px;
              height: auto;
            }
          }

          .colse {
            z-index: 2;
            width: 28px;
            height: 28px;
            position: absolute;
            top: -14px;
            right: -14px;
            cursor: pointer;
          }
          .close {
            z-index: 2;
            width: 28px;
            height: 28px;
            position: absolute;
            top: -14px;
            right: -14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.close {
  z-index: 2;
  width: 28px;
  height: 28px;
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;
}

.delete-manager-title-container {
  padding: 16px 24px 0px !important;
  width: 500px;
  flex-direction: column;
  justify-content: end;

  .close-btn {
    position: absolute;
    right: 8px;
    top: 0;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1 !important;
  }
  .close-btn:hover {
    opacity: 0.7;
  }
}

.button-box {
  display: flex;
  justify-content: flex-end;
}
.delete-manager-list {
  padding: 20px;
  #bulk_status {
    min-width: -webkit-fill-available;
    padding: 6px 12px;
    margin: 12px 0px;
    border-radius: 0.4rem;
    border: 1px solid #ced4da;
  }
  .select-status-wrapper::after {
    content: "";
    position: absolute;
    right: 34px;
    top: 132px;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4.5px solid #555;
  }
  li {
    border-bottom: 1px solid $color-gray;
    padding: 10px 0;
  }
  &:first-child {
    border-top: 1px solid $color-gray;
  }
}

.new-manager-title-container {
  padding: 32px 24px 0px !important;
  width: 900px;
  flex-direction: column;
  justify-content: end;

  .close-btn {
    position: absolute;
    right: 30px;
    top: -28px;
    background-color: #fff;
    min-width: 56px;
    min-height: 56px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000000 !important;
  }

  .new-manager-title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .new-manager-title-content {
      color: $color-blue;
      font-size: 32px;
      line-height: 0 !important;
      padding-left: 10px;
    }
  }
  .new-manager-des {
    font-size: 16px;
    margin-top: 20px;
  }
}

.new-manager-list {
  margin-top: 20px;

  li {
    border-bottom: 1px solid $color-gray;
    padding: 20px 0;

    &:first-child {
      border-top: 1px solid $color-gray;
    }

    dl {
      display: flex;
      font-size: 14px;

      dt {
        width: 20%;
        display: flex;
        min-width: 300px;
        align-items: center;
      }

      dd {
        width: 552px;
        input[type="email"] {
          height: 40px;
        }
        input[type="password"] {
          height: 40px;
        }
        .flex-box {
          display: flex;

          .flex-box-ttl {
            width: 20%;
            min-width: 100px;
          }

          .flex-box-txt {
            width: 80%;
          }
        }

        .flex-box:first-child {
          margin-bottom: 10px;
        }

        .btn-blue-small {
          margin-right: 10px;
        }

        p {
          display: flex;
          align-items: center;

          span {
            padding: 8px 10px 8px 0;
          }
        }

        input,
        textarea {
          margin-bottom: 0;
        }

        select {
          width: 100%;
        }

        textarea {
          height: 120px;
        }

        .txt-link-blue {
          color: $color-blue;
          margin-bottom: 10px;
        }

        .img-up-item {
          max-width: 126px;
          position: relative;
          margin-top: 10px;

          .img {
            img {
              width: 100%;
              object-fit: cover;
              max-width: 200px;
              height: auto;
            }
          }

          .colse {
            z-index: 6666;
            position: absolute;
            width: 40px;
            height: 40px;
            right: -20px;
            top: -20px;
          }
        }
      }
    }
    .description {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */
      margin-left: 300px;
      letter-spacing: 0.04em;

      color: #aaaaaa;
    }
  }
}
.new-manager-title-button {
  height: 219px;
  border-bottom-left-radius: 16px;

  .button-container {
    display: flex;
    justify-content: center;
  }
}

.txt-link-gray {
  color: $color-gray;
  margin-top: 5px;
}

.table-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  padding-bottom: 20px;
  letter-spacing: 0.04em;

  color: #555555;
}

.table-item {
  width: 100%;
  border-top: 1px solid $color-font1-gray;
  border-left: 1px solid $color-font1-gray;

  tr {
    th {
      padding: 30px 10px;
      font-weight: bold;
      background: rgba($color-blue, 0.03);
      border-bottom: 1px solid $color-font1-gray;
      border-right: 1px solid $color-font1-gray;
    }

    th:first-child,
    th:nth-child(n + 1) {
      width: 33%;
    }

    th:nth-child(n + 3),
    th:nth-child(n + 4) {
      width: 17%;
    }

    td {
      padding: 30px 10px;
      border-bottom: 1px solid $color-font1-gray;
      border-right: 1px solid $color-font1-gray;

      .line-right {
        border-right: 1px solid $color-gray;
        padding-right: 20px;
        margin-right: 10px;
      }

      a {
        display: inherit;
      }
    }
  }
}

.right-btn-item {
  width: 100%;
  max-width: 1280px;
  text-align: right;
  margin: 40px 0 20px auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    margin-left: 10px;
  }
}

.toggle-button-cover {
  position: relative;
  width: 260px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 260px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;

  input[type="checkbox"] {
    display: block !important;
  }
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: rgba($color-blue, 0.1);
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 18 */
#button-18 .knobs:before,
#button-18 .knobs span {
  content: "ユーザー画面で表示中";
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: $color-blue;
  border-radius: 2px;
}

#button-18 .knobs:before {
  top: 50%;
  left: 1px;
  width: 180px;
  height: 28px;
  margin-top: -5px;
  background-color: transparent;
  z-index: 2;
}

#button-18 .knobs span {
  width: 170px;
  height: 28px;
  padding: 9px 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  z-index: 1;
}

#button-18 .checkbox:active + .knobs:before {
  left: 10px;
  width: 60px;
  height: 4px;
  color: transparent;
  margin-top: -2px;
  background-color: $color-blue;
  transition: 0.3s ease all;
  overflow: hidden;
}

#button-18 .checkbox:active + .knobs span {
  width: 260px;
}

#button-18 .checkbox:checked:active + .knobs:before {
  left: auto;
  right: 10px;
  background-color: $color-red;
}

#button-18 .checkbox:checked:active + .knobs span {
  margin-left: 0px;
}

#button-18 .checkbox:checked + .knobs:before {
  content: "ユーザー画面で非表示中";
  left: 82px;
}

#button-18 .checkbox:checked + .knobs span {
  left: 86px;
  background-color: $color-red;
}

#button-18 .checkbox:checked ~ .layer {
  background-color: rgba($color-red, 0.2);
}
