.list-job-table {
  .table-cell-head {
    padding: 40px 12px;
  }
  .table-cell-jobTitle {
    width: 400px;
  }
  .table-cell-indeed,
  .table-cell-category {
    width: max-content;
  }
  .table-cell-status,
  .table-cell-listed-company-name {
    width: max-content;
  }
  .table-cell-posted-date,
  .table-cell-update-day {
    width: 160px;
  }
  .table-cell-action {
    width: 190px;
  }
  .table-cell-municipalities {
    width: 100px;
  }
}

.action-btn {
  color: white;
  font-family: "Noto Sans";
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  transition: all 0.3s ease-in-out;
  background: #00c4cc;
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  text-align: center;
  &:hover {
    color: #fcdf80;
  }
}
.action-box {
  display: flex;
  // padding: 20px;
  justify-content: space-between;
  align-items: center;
}
.btn-group-2 {
  margin-bottom: 4px;
}
.btn-full {
  width: 100%;
}

.group-btn-left {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #ccc;
    top: 0;
    right: -15px;
  }
}
