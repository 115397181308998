// palette
$color-white: #fff;
$color-black: #555;
$color-gray2:#eee;
$color-red:#FE7171;

// graph-color
$color-red2:#DD7A7A;
$color-purple:#CD8BD2;
$color-blue2:#3F94E1;
$color-blue3:#86DDD8;
$color-green:#9DCF75;
$color-yellow2:#E9EC58;


// blue
$color-blue: #00C4CC;
$color-light-blue: #F2FCFD;
$color-bg-blue:#E6FAFA;
$color-pale-blue: #CCF3F5;
$color-table-th-blue: #F7FDFE;

// yellow
$color-yellow: #40a9ff;
$color-light-yellow:#ccc;
// gray
$color-gray: #ccc;
$color-font1-gray: #aaa;
$color-dark-gray: #6c757d;

// font-size
$xxx-large: 2.8rem;
$xx-large: 2.4rem;
$x-large: 1.8rem;
$large: 1.6rem;
$normal: 1.4rem;
$small: 1.2rem;
$x-small: 1rem;

// font-family
$base-font: 'Noto Sans JP', sans-serif;
$material-icons: "Material Icons";

// text-shadow
$shadow-black: 1px 1px 1px #333;
$shadow-black-opacity20: 1px 1px 0 rgba(0,0,0,0.2);
$shadow-black-opacity40: 1px 1px 0 rgba(0,0,0,0.4);
$shadow-black-opacity60: 1px 1px 0 rgba(0,0,0,0.6);
$shadow-black-opacity80: 1px 1px 0 rgba(0,0,0,0.8);

// drop-shadow
$drop-shadow-white: drop-shadow(0px 0px 2px white);
$drop-shadow-black: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));

// media query
$mq-min: (
  'sp': 'screen and (min-width: 576px)',
  'tb': 'screen and (min-width: 768px)',
  'pc': 'screen and (min-width: 992px)',
) !default;

$mq-max: (
  's-sp': 'screen and (max-width: 374px)', //375px以下のデバイス用
  'sp': 'screen and (max-width: 575px)',
  'tb': 'screen and (max-width: 767px)',
  'pc': 'screen and (max-width: 991px)',
) !default;
