@import 'variable';

.forgot-password-container {
    display: flex;
    padding: 32px 24px 0px !important;
    width: 900px;
    height: 196px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .close-btn{
    position: absolute;
    right: 30px;
    top: -28px;
    background-color: #fff;
    min-width: 56px;
    min-height: 56px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000000 !important;
    }

    .forgot-password-title {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        // width: calc(100% - 260px);
        
        .forgot-password-content{
            color: $color-blue;
            font-size: 32px;
            line-height: 0 !important;
            padding-left: 10px;
        }
    }
    .forgot-password-des{
        font-size: 16px;
        margin-top: 20px;
    }
}



.forgot-password-list {
    margin-top: 20px;

    li {
        border-bottom: 1px solid $color-gray;
        padding: 20px 0;

        &:first-child {
            border-top: 1px solid $color-gray;
        }

        dl {
            display: flex;
            font-size: 14px;

            dt {
                width: 20%;
                display: flex;
                min-width: 300px;
                align-items: center;
            }

            dd {
                width: 552px;

                .flex-box {
                    display: flex;

                    .flex-box-ttl {
                        width: 20%;
                        min-width: 100px;
                    }

                    .flex-box-txt {
                        width: 80%;
                    }
                }

                .flex-box:first-child {
                    margin-bottom: 10px;
                }

                .btn-blue-small {
                    margin-right: 10px;
                }

                p {
                    display: flex;
                    align-items: center;

                    span {
                        padding: 8px 10px 8px 0;
                    }
                }

                input,
                textarea {
                    margin-bottom: 0;
                }

                select {
                    width: 100%;
                }

                textarea {
                    height: 120px;
                }

                .txt-link-blue {
                    color: $color-blue;
                    margin-bottom: 10px;
                }

                .img-up-item {
                    max-width: 200px;
                    position: relative;
                    margin-top: 10px;

                    .img {
                        img {
                            width: 100%;
                            object-fit: cover;
                            max-width: 200px;
                            height: auto;
                        }
                    }

                    .colse {
                        z-index: 6666;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        right: -20px;
                        top: -20px;
                    }
                }

                
            }
            
        }
        .description {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height, or 150% */
            margin-left: 300px;
            letter-spacing: 0.04em;

            color: #AAAAAA;
        }
    }
}
.forgot-password-title-button{
    height: 219px;
    border-bottom-left-radius: 16px ;

    .button-container {
        display: flex;
        justify-content: center;
    }
}