.dialog {
  
  width: 100vh;
  background-color: white;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;

  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog-title-action {
      display: flex;
      align-items: center;
      .dialog-title-text {
        font-size: 16px;
        font-weight: 600;
      }
      .dialog-title-btn {
        padding: 5px 20px;
        background-color: #2285ef;
        border-radius: 20px;
        color: white;
        display: flex;
        align-items: center;
        margin: 0 20px;
        font-size: 17px;
      }
      .dialog-title-select {
        display: flex;
        align-items: center;
        font-size: 14px;
        .dialog-title-link {
          text-decoration: none;
          color: #2285ef;
        }
      }
    }
    .dialog-title-delete {
    }
  }

  .dialog-content {
    width: 100%;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px;
      .col {
        margin-top: 20px;
        flex: 0 0 12.5%;
        margin-right: 10px;
        position: relative;
      }
    }
  }
}


.dialog_antd {
  
  
  background-color: white;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;

  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog-title-action {
      display: flex;
      align-items: center;
      .dialog-title-text {
        font-size: 16px;
        font-weight: 600;
      }
      .dialog-title-btn {
        padding: 5px 20px;
        background-color: #2285ef !important;
        border-radius: 20px;
        color: white;
        display: flex;
        align-items: center;
        margin: 0 20px;
        font-size: 17px;
      }
      .dialog-title-select {
        display: flex;
        align-items: center;
        font-size: 14px;
        .dialog-title-link {
          text-decoration: none;
          color: #2285ef;
        }
      }
    }
    .dialog-title-delete {
    }
  }

  .dialog-content {
    width: 100%;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px;
      .col {
        margin-top: 20px;
        flex: 0 0 12.5%;
        margin-right: 10px;
        position: relative;
      }
    }
  }
}
