// レイアウト汎用クラス

// display設定
.d-n {
  display: none;
}
.d-i {
  display: inline;
}
.d-b {
  display: block;
}
.d-ib {
  display: inline-block;
}
.d-tb {
  display: table;
}
.d-tbc {
  display: table-cell;
}
.d-tbr {
  display: table-row;
}
.d-f {
  display: flex;
}
.d-if {
  display: inline-flex;
}
.display-t {
  display: table;
  width: 100%;
}

@include mq-max(sp) {
  .sp-d {
    display: block;
  }
  .tb-d {
    display: none;
  }
  .pc-d {
    display: none;
  }
}

@include mq-max(tb) {
  .sp-d {
    display: block;
  }
  .tb-d {
    display: block;
  }
  .pc-d {
    display: none;
  }
}

@include mq-min(pc) {
  .sp-d {
    display: none;
  }
  .tb-d {
    display: none;
  }
  .pc-d {
    display: block;
  }
}

// table-layout
.t-layout-f {
  table-layout: fixed;
}

//
.fxw-w {
  flex-wrap: wrap;
}

.fl-d {
  flex-direction: column;
}

// justify-content設定
.jc-sb {
  justify-content: space-between;
}

.jc-ct {
  justify-content: center;
}

// align-items設定
.ai-c {
  align-items: center;
}
.ai-b {
  align-items: baseline;
}

// flex子要素の幅指定
.f-1 {
  flex: 1;
}

// vertical設定
.va-t {
  vertical-align: top;
}
.va-m {
  vertical-align: middle;
}

// position
.pos-a {
  position: absolute;
}
.pos-r {
  position: relative;
}
.pos-f {
  position: fixed;
}
.sticky {
  position: sticky;
  z-index: 1000;
}

// float
.fl-l {
  float: left;
}
.fl-r {
  float: right;
}
.clear {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}


// overflow
.ov-h {
  overflow: hidden;
}

// margin, padding
$numList: 0, 4, 8, 10, 12, 16, 20, 24, 32, 40, 60, 80;
$marginList: m, mt, mr, mb, ml, mlr, mtb;
$paddingList: p, pt, pr, pb, pl, pd, plr, ptb;
@each $margin in $marginList {
  @each $num in $numList {
    @if $margin == m {
      .#{$margin}-#{$num} { margin: #{$num}px !important; }
    } @else if $margin == mt {
      .#{$margin}-#{$num} { margin-top: #{$num}px !important; }
    } @else if $margin == mr {
      .#{$margin}-#{$num} { margin-right: #{$num}px !important; }
    } @else if $margin == mb {
      .#{$margin}-#{$num} { margin-bottom: #{$num}px !important; }
    } @else if $margin == ml {
      .#{$margin}-#{$num} { margin-left: #{$num}px !important; }
    } @else if $margin == mlr {
      .#{$margin}-#{$num} { margin-left: #{$num}px !important; margin-right: #{$num}px !important; }
    } @else if $margin == mtb {
      .#{$margin}-#{$num} { margin-top: #{$num}px !important; margin-bottom: #{$num}px !important; }
    }
  }
}
.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }

@each $padding in $paddingList {
  @each $num in $numList {
    @if $padding == p {
      .#{$padding}-#{$num} { padding: #{$num}px !important; }
    } @else if $padding == pt {
      .#{$padding}-#{$num} { padding-top: #{$num}px !important; }
    } @else if $padding == pr {
      .#{$padding}-#{$num} { padding-right: #{$num}px !important; }
    } @else if $padding == pb {
      .#{$padding}-#{$num} { padding-bottom: #{$num}px !important; }
    } @else if $padding == pl {
      .#{$padding}-#{$num} { padding-left: #{$num}px !important; }
    } @else if $padding == plr {
      .#{$padding}-#{$num} { padding-left: #{$num}px !important; padding-right: #{$num}px !important; }
    } @else if $padding == ptb {
      .#{$padding}-#{$num} { padding-top: #{$num}px !important;  padding-bottom: #{$num}px !important; }
    }
  }
}

// width
.w-40 {
  width: 40px;
}
.w-60 {
  width: 60px;
}
.w-88 {
  width: 88px;
}

.w-150 {
  width: 150px;
}

$widthPerList: 10, 15, 20, 25, 30, 40, 45, 50, 55 , 60, 65, 70, 80, 85, 90, 95, 100;
  @each $width in $widthPerList {
      .w-#{$width}per { width: #{$width}#{'%'} !important }
  }

.mw-100per {
  max-width: 100%;
}

// border
.bd-n {
  border: none;
}
.border-gray-a {
  border: solid 1px $color-font1-gray;
}

//border-radius
.bdrs-4 {
  border-radius: 4px;
}
.bdrs-16 {
  border-radius: 16px;
}

//text-align
.ta-l {
  text-align: left;
}
.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}

//font-weight
.bold {
  font-weight: bold;
}
.text_0ABAB5{
  color: #0ABAB5;
}
.normal {
  font-weight: normal;
}

//font-size
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

// shadow
.drop-shadow-white {
  filter: $drop-shadow-white;
}
.drop-shadow-black {
  filter: $drop-shadow-black;
}

// list-style
.list-style-position-i {
  list-style-position: inside;
}
.pl1em-ti1em {
  padding-left: 1em;
  text-indent: -1em;
}

// vertical-align
.v-align-middle {
  vertical-align: middle;
}

//z-index
$z-indexList: 0, 1, 2, 3, 4, 5, 10, 20, 30, 50, 100, 9999;
  @each $z-index in $z-indexList {
      .z-idx-#{$z-index} { z-index: #{$z-index} }
  }

// iPhone用拡大回避設定
.touch-action-m {
  touch-action: manipulation;
}
.scale-tr-72 {
  font-size: 1.6rem;
  line-height: 1;
  transform-origin: top right;
  transform: scale(0.72);
}

//cursor
.cur-p {
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}
.cur-d {
  cursor: default;
}
