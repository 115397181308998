html {
  color: #000;
  background: #fff;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  font-variant: normal;
  border: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

html {
  background-color: #f2fcfd;
}

body {
  color: #555;
  background-color: #f2fcfd !important;
  font-family: Noto Sans, sans-serif !important;
}

body * {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

dl {
  list-style-type: none;
}

img {
  vertical-align: bottom;
  border-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.text-color-black {
  color: #555;
}

.text-center {
  text-align: center;
}

button {
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
}

input[type="date"] {
  background: #fff;
}

input[type="checkbox"] {
  display: none;
}

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
}

#container {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.container {
  background: #fff;
  border-radius: 16px;
  margin: 0 auto;
  padding: 40px;
}

.content-wrapper {
  max-width: 1536px;
  margin: 0 auto;
}

#wrapper {
  text-align: center;
  background-color: #f2fcfd0d;
  padding: 0 40px 50px;
  position: relative;
}

#wrapper-gray {
  text-align: center;
  background-color: #eee;
  padding-bottom: 100px;
  position: relative;
}

.inner {
  width: 100%;
  max-width: 1360px;
  text-align: left;
  background: #fff;
  border-radius: 16px;
  margin: 80px auto 40px;
  padding: 80px 50px;
  position: relative;
}

.inner-block {
  max-width: 1440px;
  margin: auto;
  padding: 0 40px;
}

.inner-login {
  width: 100%;
  max-width: 490px;
  text-align: center;
  background: #fff;
  border-radius: 16px;
  margin: 80px auto 40px;
  padding: 80px 50px;
}

.inner-company, .inner-results {
  width: 100%;
  max-width: 1456px;
  text-align: left;
  background: #e6fafa;
  border-radius: 0 16px 16px;
  margin: 0 auto 40px;
  padding: 40px;
  position: relative;
}

.panel-detail-appplicant {
  width: 100%;
  text-align: left;
  background: #e6fafa;
  border-radius: 0 16px 16px;
  margin: 0 auto 40px;
  padding: 40px;
  position: relative;
}

.section-ttl {
  color: #00c4cc;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

#footer {
  color: #aaa;
  font-size: 16px;
}

#footer span {
  font-size: 12px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t--30 {
  margin-top: -30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t--50 {
  margin-top: -50px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-27 {
  margin-bottom: 27px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-136 {
  margin-left: 136px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.w-full {
  min-width: 100% !important;
}

textarea {
  resize: none;
}

h1 {
  color: #00c4cc;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

h1 span {
  font-size: 16px;
}

.float-left {
  float: left;
}

.txt-center {
  text-align: center;
  margin: auto;
}

.checkbox-item {
  flex-wrap: wrap;
  display: flex;
}

.checkbox-item div {
  width: 25%;
}

.items-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.align-center {
  align-items: center;
  display: flex;
}

.display-flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.position-relative {
  position: relative;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  border-radius: 16px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 500px !important;
  border-radius: 6px !important;
}

.icon-forgot-password {
  margin-bottom: 2px !important;
  font-size: 24px !important;
}

.no-border {
  border: none !important;
}

button:focus, select:focus, input:focus {
  outline: 0;
}

.toastParent {
  position: relative;
  inset: 0;
}

.toast {
  color: #43766c;
  opacity: .9;
  z-index: 50000000;
  background-color: #cff7ec;
  border-radius: 30px;
  padding: 10px 30px;
  animation: toast .7s ease-in;
  position: fixed;
  top: 8%;
  left: 40%;
}

.toastFail {
  color: #fe7171;
  opacity: .9;
  z-index: 50000000;
  background-color: #ffdcdc;
  border-radius: 30px;
  padding: 10px 30px;
  animation: toast .7s ease-in;
  position: fixed;
  top: 8%;
  left: 40%;
}

.h-20 {
  height: 20px;
}

.grow-1 {
  flex-grow: 1;
}

.grow-400 {
  flex-grow: 400;
}

.grow-200 {
  flex-grow: 200;
}

.grow-300 {
  flex-grow: 300;
}

.m-w-1360 {
  max-width: 1360px !important;
}

.relative {
  position: relative;
}

.color-focus {
  color: #fcdf80 !important;
}

.common-text-blue {
  text-align: center;
  letter-spacing: .04em;
  color: #00c4cc;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.common-text-blue-normal {
  text-align: center;
  letter-spacing: .04em;
  color: #00c4cc;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.common-text-black {
  letter-spacing: .04em;
  color: #555;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.common-text-gray {
  letter-spacing: .04em;
  color: #aaa;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.blank-block {
  height: 20px;
  width: 100%;
  opacity: 0;
}

@keyframes toast {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

header {
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
  display: flex;
}

header nav {
  align-items: center;
  margin-left: 54px;
  display: flex;
}

header nav a {
  color: #fff;
  padding: 0 20px;
}

header nav .nav-toggle {
  padding: 0 32px 0 20px;
}

header nav .nav-toggle.bottom-triangle:after {
  color: #fff;
}

header nav .nav-toggle .menu {
  top: auto;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

header nav .color-focus {
  color: #ffeaaf;
}

header nav .current {
  color: #40a9ff;
}

header .header-logo {
  width: 190px;
}

header .header-search {
  margin: 0 20px 0 0;
  position: relative;
}

header .header-search input[type="text"] {
  border-radius: 20px;
  margin-bottom: 0;
}

header .header-search .search-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

header .header-account {
  position: relative;
}

header .header-user {
  padding-right: 32px;
}

header .header-user.bottom-triangle:after {
  color: #fff;
}

header #tg {
  display: none;
}

header .menu {
  visibility: hidden;
  opacity: 0;
  margin-top: 8px;
  list-style: none;
  position: absolute;
  bottom: auto;
  right: 0;
  box-shadow: 0 0 5px #ccc;
}

header .menu a, header .menu button {
  width: 100%;
  min-width: max-content;
  text-align: center;
  color: #333;
  background-color: #fff;
  padding: 12px;
  font-size: 14px;
  text-decoration: none;
  display: block;
  position: relative;
}

header .menu a:hover, header .menu button:hover {
  color: #fff;
  opacity: 1;
  background-color: #00c4cc;
}

header .dropInput:checked ~ .menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

header .dropInput:checked ~ .color-check {
  color: #ffeaaf;
}

header .dropdown-bt:hover {
  cursor: pointer;
}

header .dropdown-bt:after {
  content: "";
  min-height: 22px;
  min-width: 30px;
  z-index: 1;
  position: absolute;
  right: -30px;
}

.breadcrumb {
  padding: 0;
}

.breadcrumb a {
  color: #555;
  text-align: left;
  margin: 20px 0 40px;
  padding-left: 12px;
  position: relative;
}

.breadcrumb a span {
  width: 6px;
  height: 6px;
  content: "";
  border-bottom: 1px solid #555;
  border-left: 1px solid #555;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 8px;
  transform: translateY(-50%)rotate(45deg);
}

.bottom-triangle {
  position: relative;
}

.bottom-triangle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input::placeholder, textarea::placeholder {
  color: #aaa;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:before {
  content: "";
  width: 0;
  border-bottom: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 10px;
  right: 9px;
}

.select-wrapper:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 21px;
  right: 9px;
}

.select-wrapper select.select {
  padding: 0 10px;
}

form.applicants-detail .applicants-detail-anchor {
  width: 20%;
  position: relative;
}

form.applicants-detail .applicants-detail-anchor ul {
  position: sticky;
  top: 20px;
}

form.applicants-detail .applicants-detail-anchor ul li {
  margin-bottom: 20px;
  padding-bottom: 8px;
}

form.applicants-detail .applicants-detail-anchor ul li.current {
  border-bottom: 2px solid #00c4cc;
  font-weight: bold;
  display: inline-block;
}

form.applicants-detail .applicants-detail-anchor ul li a {
  color: #555;
}

form.applicants-detail .applicants-detail-right {
  width: 100%;
}

form.applicants-detail .applicants-detail-list ul li {
  border-bottom: 1px solid #ccc;
  padding: 16px 0;
}

form.applicants-detail .applicants-detail-list ul li:first-of-type {
  padding-top: 0;
}

form.applicants-detail .applicants-detail-list ul li:last-of-type {
  border-bottom: 0;
}

form.applicants-detail .applicants-detail-list ul li dl {
  display: flex;
}

form.applicants-detail .applicants-detail-list ul li dl dt {
  width: 20%;
}

form.applicants-detail .applicants-detail-btn {
  justify-content: space-between;
}

dd.dd-inner dl {
  border-top: 1px solid #ccc;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 0;
}

dd.dd-inner dl dd .select-wrapper {
  width: 20%;
  margin-left: auto;
}

dd.dd-inner dl dd .select-wrapper select.select {
  padding: 0 10px;
}

dd.dd-inner dl dd .input-txt {
  width: 78%;
}

.job-new-form .select-wrapper:before {
  display: none;
}

.job-new-form .select-wrapper:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.job-new-form li dl dt {
  margin-top: 8px;
}

.sort-arrow img {
  width: 25px;
}

.q-icon {
  margin-top: 2px;
}

.btn-blue-big {
  color: #fff;
  height: 55px;
  cursor: pointer;
  background: #00c4cc;
  border: none;
  border-radius: 30px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: bold;
}

.btn-red-big {
  color: #fff;
  cursor: pointer;
  background: #fe7171;
  border: none;
  border-radius: 30px;
  padding: 14px 60px;
  font-size: 16px;
  font-weight: bold;
}

.btn-blue-medium {
  color: #fff;
  cursor: pointer;
  background: #00c4cc;
  border: none;
  border-radius: 28px;
  padding: 8px 60px;
  font-size: 16px;
  font-weight: bold;
}

.btn-blue-small {
  color: #00c4cc;
  cursor: pointer;
  max-width: 118px;
  background: #00c4cc0d;
  border: 1px solid #00c4cc;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 14px;
}

.btn-border-blue {
  color: #00c4cc;
  cursor: pointer;
  background: #fff;
  border: 1px solid #00c4cc;
  border-radius: 20px;
  padding: 8px 20px;
}

.btn-square-blue {
  color: #00c4cc;
  cursor: pointer;
  background: #fff;
  border: 1px solid #00c4cc;
  padding: 8px 20px;
}

.btn-border-blue-padding-plus {
  color: #00c4cc;
  cursor: pointer;
  background: #fff;
  border: 1px solid #00c4cc;
  border-radius: 20px;
  padding: 8px 40px;
}

.btn-border-blue-blod-big {
  color: #00c4cc;
  cursor: pointer;
  background: #fff;
  border: 2px solid #00c4cc;
  border-radius: 30px;
  padding: 14px 60px;
  font-weight: bold;
}

.btn-border-blue-blod-medium {
  color: #00c4cc;
  cursor: pointer;
  background: #fff;
  border: 2px solid #00c4cc;
  border-radius: 28px;
  padding: 8px 40px;
  font-weight: bold;
}

.btn-square-red {
  color: #fff;
  cursor: pointer;
  background: #fe7171;
  border: none;
  border-radius: 2px;
  padding: 10px 34px;
  font-size: 16px;
  font-weight: bold;
}

.btn-small-square-blue {
  color: #fff;
  cursor: pointer;
  background: #00c4cc;
  border: none;
  border-radius: 2px;
  padding: 10px 34px;
  font-size: 16px;
  font-weight: bold;
}

.btn-square-gray {
  color: #fff;
  cursor: pointer;
  background: #6c757d;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px !important;
}

.btn-square-gray:hover {
  opacity: .7;
}

.btn-flex-item {
  text-align: center;
  margin: auto;
}

.btn-flex-item input {
  margin: 0 10px;
}

textarea {
  max-width: 100% !important;
}

input[type="date"] {
  max-width: 340px;
}

.input-w-200 {
  max-width: 200px;
  margin-right: 5px;
}

.select {
  color: #555;
  cursor: pointer;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  padding: 6px 14px 6px 10px;
  font-size: 16px;
}

select:after {
  background: url("https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png") 100% no-repeat;
}

.select-item {
  position: relative;
}

.select-item .select {
  width: 100%;
}

.select-item:before {
  content: "";
  width: 0;
  height: 0;
  pointer-events: none;
  border: 4px solid #0000;
  border-top-width: 0;
  border-bottom: 5px solid #555;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  transform: rotate(180deg);
}

.select-item-w340 {
  width: 100%;
  max-width: 340px;
}

.select-item-w150 {
  width: 100%;
  max-width: 150px;
}

.checkbox-search {
  box-sizing: border-box;
  cursor: pointer;
  width: auto;
  padding: 5px 30px;
  display: inline-block;
  position: relative;
}

.checkbox-search:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #ccc;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
}

.checkbox-search:after {
  content: "";
  height: 20px;
  opacity: 0;
  width: 9px;
  border-bottom: 3px solid #00c4cc;
  border-right: 6px solid #00c4cc;
  margin-top: -16px;
  display: block;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: rotate(45deg);
}

input[type="checkbox"]:checked + .checkbox-search:before {
  border-color: #aaa;
}

input[type="checkbox"]:checked + .checkbox-search:after {
  opacity: 1;
}

.tag-red {
  color: #fff;
  max-height: 22px;
  background: #fe7171;
  border-radius: 2px;
  margin-left: 8px;
  font-size: 13px;
  padding: 1px 5px !important;
}

.tag-gray {
  color: #fff;
  max-height: 22px;
  background: #aaa;
  border-radius: 2px;
  margin-left: 8px;
  font-size: 13px;
  padding: 1px 5px !important;
}

.not_required_admin {
  color: #595959;
  max-height: 22px;
  background: #fafafa;
  border-radius: 2px;
  font-size: 13px;
  padding: 1px 5px !important;
}

.not_required {
  color: #fff;
  max-height: 22px;
  background: #aaa;
  border-radius: 2px;
  font-size: 13px;
  padding: 1px 5px !important;
}

.tag-edit, .tag-duplicate, .tag-preview {
  color: #fff;
  max-height: 22px;
  background: #00c4cc;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 13px;
  padding: 1px 5px !important;
}

.tag-edit a, .tag-duplicate a, .tag-preview a {
  color: #fff;
}

.tag-del {
  color: #00c4cc;
  max-height: 22px;
  border-radius: 2px;
  margin-left: 23px;
  padding: 1px 5px;
  font-size: 13px;
}

.icon-qa {
  margin-left: 8px;
}

.icon-qa img {
  max-width: 14px;
  margin-bottom: 4px;
}

.icon-qa-big {
  margin-left: 8px;
}

.icon-qa-big img {
  max-width: 30px;
  margin-bottom: 4px;
}

.btn-more {
  color: #00c4cc;
  cursor: pointer;
  border: 2px solid #00c4cc;
  border-radius: 6px;
  margin-top: 20px;
  padding: 15px;
  font-weight: bold;
}

.btn-more-add {
  color: #00c4cc;
  cursor: pointer;
  border: 1px solid #00c4cc;
  border-radius: 6px;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

.btn-more-add img {
  max-width: 10px;
  margin-right: 5px;
}

.btn-more-open {
  color: #00c4cc;
  cursor: pointer;
  text-align: center;
  border: 2px solid #00c4cc;
  border-radius: 6px;
  margin-top: 20px;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
}

.form-btn-area {
  text-align: center;
  margin: 40px auto 0;
}

.form-btn-area.sticky {
  bottom: 20px;
}

.common-box {
  background: #fff;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 0 8px -2px #ccc;
}

.common-box .common-text {
  letter-spacing: .04em;
  color: #0abab5;
  margin-bottom: 20px;
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}

.common-box .field-group {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: flex;
}

.common-box .field-group-name {
  width: 300px;
  align-items: center;
  display: flex;
}

.common-box .field-group-data {
  flex: 1;
}

.common-box .field-group-data-1 {
  display: flex;
}

.common-box input[type="checkbox"] {
  display: block;
}

.common-box .field-inner-left {
  width: 300px;
}

.common-box .sub-field-group {
  align-items: center;
  display: flex;
}

.common-box .sub-field-group > :first-child {
  width: 150px;
}

.common-box .sub-field-group > :last-child, .common-box .field-inner-right {
  flex: 1;
}

.common-box .field-name {
  letter-spacing: .04em;
  color: #555;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.common-box .MuiFormControl-root {
  height: unset;
  background: unset;
}

.common-box textarea {
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
}

.common-box textarea:focus {
  border: 1px solid #fcdf80;
}

.common-box .has-datepicker > :first-child {
  height: 40px;
}

.count-text {
  letter-spacing: .04em;
  color: #aaa;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

@media (max-width: 1024px) {
  .common-box .field-group-name {
    width: 200px;
  }

  .common-box .field-group-data-1 {
    width: 100%;
    flex-direction: column;
  }

  .common-box .field-name {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .field-group {
    flex-direction: column;
  }

  .field-group .field-group-name {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .common-box .sub-field-group {
    flex-direction: column;
    align-items: start;
  }
}

.top-status {
  margin-top: 40px;
  margin-bottom: 20px;
}

ul.summary-select {
  justify-content: space-between;
  display: flex;
}

ul.summary-select li {
  width: 18%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px 10px 30px;
}

ul.summary-select li .status-count {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  display: block;
}

ul.summary-select li .status-count span {
  font-size: 14px;
}

ul.summary-select select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.top-data {
  margin-bottom: 170px;
}

.top-data hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  margin: 20px 0 28px;
}

.top-data .summary-count {
  background: #ccf3f5;
  border-radius: 4px;
  margin-top: 68px;
  padding: 12px 20px;
}

.top-message {
  margin: 60px 0;
  padding: 40px;
}

.top-message h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.top-message ul li {
  border-top: 1px solid #ccc;
  padding: 20px 0;
}

.top-message ul li:last-of-type {
  padding-bottom: 0;
}

.top-message ul li p {
  font-size: 14px;
}

.top-message ul li p span {
  margin-bottom: 4px;
  font-size: 16px;
}

input[type="checkbox"] {
  display: none;
}

.checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  width: auto;
  padding-left: 30px;
  display: inline-block;
  position: relative;
}

.checkbox01:before {
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
}

.checkbox01:after {
  content: "";
  height: 9px;
  opacity: 0;
  width: 5px;
  border-bottom: 3px solid #555;
  border-right: 3px solid #555;
  margin-top: -7px;
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: rotate(45deg);
}

input[type="checkbox"]:checked + .checkbox01:after {
  opacity: 1;
}

.list-checkbox input[type="checkbox"] {
  display: none;
}

.list-checkbox input[type="checkbox"]:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #fff;
  border: 1px solid #231815;
  margin-top: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
}

.list-checkbox input[type="checkbox"]:after {
  content: "";
  height: 9px;
  opacity: 0;
  width: 5px;
  border-bottom: 3px solid #ed7a9c;
  border-right: 3px solid #ed7a9c;
  margin-top: -7px;
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: rotate(45deg);
}

.list-checkbox input[type="checkbox"]:checked + .checked:after {
  opacity: 1;
}

.list-table {
  width: 100%;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.list-table tr th, .list-table tr td {
  height: 120px;
  background: #f7fdfe;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 28px 10px;
  font-weight: bold;
}

.list-table tr th .small-txt {
  font-size: 13px;
}

.list-table tr td {
  background: #fff;
  padding: 26px 10px;
  font-size: 14px;
  font-weight: normal;
}

.list-table tr td select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.list-table tr td .line-right {
  width: 65%;
  border-right: 1px solid #ccc;
  flex-wrap: wrap;
  margin-right: 10px;
  padding-right: 20px;
  display: flex;
}

.list-table tr td .line-right .tag-button {
  margin: 0 4px 4px 0;
}

.list-table tr td .line-right a {
  display: inline;
}

.list-table tr td.list-table-memo {
  display: table-cell;
}

.list-table tr td .employment-status-tag {
  background: #eee;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
}

.applicants-message-button {
  color: #fff;
  background: #00c4cc;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
}

.list-count {
  display: flex;
}

.list-count li {
  margin-right: 8px;
}

.list-sort {
  margin: 20px 0 75px;
}

.list-sort select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.list-sort .input-txt {
  border: 1px solid #fff8e5;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 16px;
}

.applicant-list-sort {
  grid-template-columns: 100px auto;
  column-gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.applicant-list-sort form {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.applicant-list-sort form .list-sort-item {
  width: 30%;
  margin-bottom: 12px;
}

.wrapp-input {
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
  display: grid;
}

.input-txt, .select-wrapper {
  width: 340px;
  margin-bottom: 0;
  margin-right: 12px;
}

.input-txt select, .select-wrapper select {
  margin-bottom: 0;
}

.job-list .job-list-top-btn, .applicants-list .job-list-top-btn {
  justify-content: flex-end;
}

.applicants-list tr th:first-of-type {
  width: 60px;
}

.applicants-list tr th:nth-of-type(2) {
  width: 130px;
}

.applicants-list tr th:nth-of-type(3), .applicants-list tr th:nth-of-type(4) {
  width: 280px;
}

.applicants-list tr th:nth-of-type(5) {
  width: 220px;
}

.applicants-list tr th:nth-of-type(6) {
  width: 130px;
}

.applicants-list tr th:last-of-type {
  width: 186px;
}

.blank {
  min-width: max-content;
  min-height: 167px;
  background-color: #f2fcfd;
}

.checkbox-selected-item {
  width: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.checkbox-selected-item p {
  font-size: 14px;
  font-weight: bold;
}

.th-sort {
  position: relative;
}

.th-sort .th-sort-arrow {
  margin-left: 8px;
}

.th-sort .th-sort-arrow:before {
  content: "";
  width: 0;
  border-bottom: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 5px;
}

.th-sort .th-sort-arrow:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 14px;
}

.th-sort .th-sort-arrow.th-sort-2row:before {
  top: 15px;
}

.th-sort .th-sort-arrow.th-sort-2row:after {
  top: 26px;
}

.login-item {
  margin-bottom: 30px;
}

.login-item dt, .login-item dd {
  text-align: left;
}

.login-item dt {
  font-weight: bold;
}

.login-item .error {
  color: red;
}

.boder-error {
  border: 2px solid red;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 10px 0;
}

.boder-error .error {
  color: red;
}

.password-item {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px auto 40px;
  padding: 20px;
  display: flex;
}

.password-item dt {
  min-width: 250px;
  padding: 8px 20px 8px 0;
}

.password-item dd {
  width: inherit;
}

.login-logo {
  margin-bottom: 60px;
}

.login-logo img {
  max-width: 242px;
}

.company-item {
  width: 100%;
  text-align: left;
  background: #fff;
  border-radius: 16px;
  flex-direction: column;
  margin-top: 20px;
  padding: 40px;
  display: flex;
  position: relative;
}

.company-item .btn-add {
  max-width: 213px;
  height: 40px;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  padding: 8px 40px;
  display: flex;
}

.company-item:first-child {
  margin-top: 0;
}

.company-h1-item {
  width: 100%;
  max-width: 1456px;
  margin: 40px auto 0;
  display: flex;
}

.company-h1-on {
  color: #00c4cc;
  width: fit-content;
  background: #e6fafa;
  border-radius: 8px 8px 0 0;
  margin: 0 10px 0 0;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
}

.company-h1-off {
  color: #555;
  width: fit-content;
  background: #fff;
  border-radius: 8px 8px 0 0;
  margin: 0 10px 0 0;
  padding: 20px;
  font-size: 18px;
}

.company-h1-off a {
  color: #555;
}

.company-ttl-item {
  display: flex;
}

.company-ttl-item .company-ttl {
  width: calc(100% - 260px);
  font-size: 16px;
  font-weight: bold;
}

.company-ttl-item .company-img {
  width: 100%;
}

.company-ttl-item .company-btn {
  justify-content: flex-end;
  display: flex;
}

.company-ttl-item .company-btn a {
  cursor: pointer;
}

.company-ttl-item .company-btn a img {
  width: 25px;
}

.company-list {
  margin-top: 20px;
}

.company-list li {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.company-list li:first-child {
  border-top: 1px solid #ccc;
}

.company-list li dl {
  font-size: 14px;
  display: flex;
}

.company-list li dl dt {
  width: 20%;
  min-width: 230px;
  display: flex;
}

.company-list li dl dd {
  width: 80%;
}

.company-list li dl dd .flex-box {
  display: flex;
}

.company-list li dl dd .flex-box .flex-box-ttl {
  width: 20%;
  min-width: 100px;
}

.company-list li dl dd .flex-box .flex-box-txt {
  width: 80%;
}

.company-list li dl dd .flex-box:first-child {
  margin-bottom: 10px;
}

.company-list li dl dd .btn-blue-small {
  margin-right: 10px;
}

.company-list li dl dd p {
  align-items: center;
  display: flex;
}

.company-list li dl dd p span {
  padding: 8px 10px 8px 0;
}

.company-list li dl dd input, .company-list li dl dd textarea {
  margin-bottom: 0;
}

.company-list li dl dd select {
  width: 100%;
}

.company-list li dl dd textarea {
  height: 120px;
}

.company-list li dl dd .txt-link-blue {
  color: #00c4cc;
  margin-bottom: 10px;
}

.company-list li dl dd .img-up-item {
  max-width: 200px;
  margin-top: 10px;
  position: relative;
}

.company-list li dl dd .img-up-item .img img {
  width: 100%;
  object-fit: cover;
  max-width: 200px;
  height: auto;
}

.company-list li dl dd .img-up-item .colse, .company-list li dl dd .img-up-item .close, .close {
  z-index: 2;
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  top: -14px;
  right: -14px;
}

.delete-manager-title-container {
  width: 500px;
  flex-direction: column;
  justify-content: end;
  padding: 16px 24px 0 !important;
}

.delete-manager-title-container .close-btn {
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 1 !important;
}

.delete-manager-title-container .close-btn:hover {
  opacity: .7;
}

.button-box {
  justify-content: flex-end;
  display: flex;
}

.delete-manager-list {
  padding: 20px;
}

.delete-manager-list #bulk_status {
  min-width: -webkit-fill-available;
  border: 1px solid #ced4da;
  border-radius: .4rem;
  margin: 12px 0;
  padding: 6px 12px;
}

.delete-manager-list .select-status-wrapper:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 132px;
  right: 34px;
}

.delete-manager-list li {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.delete-manager-list:first-child {
  border-top: 1px solid #ccc;
}

.new-manager-title-container {
  width: 900px;
  flex-direction: column;
  justify-content: end;
  padding: 32px 24px 0 !important;
}

.new-manager-title-container .close-btn {
  min-width: 56px;
  min-height: 56px;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -28px;
  right: 30px;
  box-shadow: 0 0 8px #0000001a;
  z-index: 10000000 !important;
}

.new-manager-title-container .new-manager-title {
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.new-manager-title-container .new-manager-title .new-manager-title-content {
  color: #00c4cc;
  padding-left: 10px;
  font-size: 32px;
  line-height: 0 !important;
}

.new-manager-title-container .new-manager-des {
  margin-top: 20px;
  font-size: 16px;
}

.new-manager-list {
  margin-top: 20px;
}

.new-manager-list li {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.new-manager-list li:first-child {
  border-top: 1px solid #ccc;
}

.new-manager-list li dl {
  font-size: 14px;
  display: flex;
}

.new-manager-list li dl dt {
  width: 20%;
  min-width: 300px;
  align-items: center;
  display: flex;
}

.new-manager-list li dl dd {
  width: 552px;
}

.new-manager-list li dl dd input[type="email"], .new-manager-list li dl dd input[type="password"] {
  height: 40px;
}

.new-manager-list li dl dd .flex-box {
  display: flex;
}

.new-manager-list li dl dd .flex-box .flex-box-ttl {
  width: 20%;
  min-width: 100px;
}

.new-manager-list li dl dd .flex-box .flex-box-txt {
  width: 80%;
}

.new-manager-list li dl dd .flex-box:first-child {
  margin-bottom: 10px;
}

.new-manager-list li dl dd .btn-blue-small {
  margin-right: 10px;
}

.new-manager-list li dl dd p {
  align-items: center;
  display: flex;
}

.new-manager-list li dl dd p span {
  padding: 8px 10px 8px 0;
}

.new-manager-list li dl dd input, .new-manager-list li dl dd textarea {
  margin-bottom: 0;
}

.new-manager-list li dl dd select {
  width: 100%;
}

.new-manager-list li dl dd textarea {
  height: 120px;
}

.new-manager-list li dl dd .txt-link-blue {
  color: #00c4cc;
  margin-bottom: 10px;
}

.new-manager-list li dl dd .img-up-item {
  max-width: 126px;
  margin-top: 10px;
  position: relative;
}

.new-manager-list li dl dd .img-up-item .img img {
  width: 100%;
  object-fit: cover;
  max-width: 200px;
  height: auto;
}

.new-manager-list li dl dd .img-up-item .colse {
  z-index: 6666;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
}

.new-manager-list li .description {
  letter-spacing: .04em;
  color: #aaa;
  margin-left: 300px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.new-manager-title-button {
  height: 219px;
  border-bottom-left-radius: 16px;
}

.new-manager-title-button .button-container {
  justify-content: center;
  display: flex;
}

.txt-link-gray {
  color: #ccc;
  margin-top: 5px;
}

.table-title {
  letter-spacing: .04em;
  color: #555;
  padding-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.table-item {
  width: 100%;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
}

.table-item tr th {
  background: #00c4cc08;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
  font-weight: bold;
}

.table-item tr th:first-child, .table-item tr th:nth-child(n+1) {
  width: 33%;
}

.table-item tr th:nth-child(n+3), .table-item tr th:nth-child(n+4) {
  width: 17%;
}

.table-item tr td {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
}

.table-item tr td .line-right {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 20px;
}

.table-item tr td a {
  display: inherit;
}

.right-btn-item {
  width: 100%;
  max-width: 1280px;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0 20px auto;
  display: flex;
}

.right-btn-item input {
  margin-left: 10px;
}

.toggle-button-cover {
  width: 260px;
  position: relative;
}

.button-cover, .knobs, .layer {
  position: absolute;
  inset: 0;
}

.button {
  width: 260px;
  height: 36px;
  margin: -20px auto 0;
  position: relative;
  top: 50%;
  overflow: hidden;
}

.button input[type="checkbox"] {
  display: block !important;
}

.button.r, .button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
  margin: 0;
  padding: 0;
  position: relative;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  z-index: 1;
  background-color: #00c4cc1a;
  transition: all .3s;
}

#button-18 .knobs:before, #button-18 .knobs span {
  content: "ユーザー画面で表示中";
  color: #fff;
  text-align: center;
  background-color: #00c4cc;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 4px;
  left: 4px;
}

#button-18 .knobs:before {
  width: 180px;
  height: 28px;
  z-index: 2;
  background-color: #0000;
  margin-top: -5px;
  top: 50%;
  left: 1px;
}

#button-18 .knobs span {
  width: 170px;
  height: 28px;
  z-index: 1;
  padding: 9px 4px;
  transition: all .3s, left .3s cubic-bezier(.18, .89, .35, 1.15);
}

#button-18 .checkbox:active + .knobs:before {
  width: 60px;
  height: 4px;
  color: #0000;
  background-color: #00c4cc;
  margin-top: -2px;
  transition: all .3s;
  left: 10px;
  overflow: hidden;
}

#button-18 .checkbox:active + .knobs span {
  width: 260px;
}

#button-18 .checkbox:checked:active + .knobs:before {
  background-color: #fe7171;
  left: auto;
  right: 10px;
}

#button-18 .checkbox:checked:active + .knobs span {
  margin-left: 0;
}

#button-18 .checkbox:checked + .knobs:before {
  content: "ユーザー画面で非表示中";
  left: 82px;
}

#button-18 .checkbox:checked + .knobs span {
  background-color: #fe7171;
  left: 86px;
}

#button-18 .checkbox:checked ~ .layer {
  background-color: #fe717133;
}

.anaysis-item .inner {
  padding: 40px !important;
}

.calander-item {
  align-items: center;
  display: flex;
}

.calander-item .btn-blue-medium {
  margin-left: 20px;
  padding: 8px 40px !important;
}

.calander-item input[type="date"] {
  margin-bottom: 0 !important;
}

input[type="checkbox"] {
  display: none;
}

.checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  width: auto;
  padding-left: 30px;
  display: inline-block;
  position: relative;
}

.checkbox01:before {
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
}

.checkbox01:after {
  content: "";
  height: 9px;
  opacity: 0;
  width: 5px;
  border-bottom: 3px solid #555;
  border-right: 3px solid #555;
  margin-top: -7px;
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: rotate(45deg);
}

input[type="checkbox"]:checked + .checkbox01:after {
  opacity: 1;
}

.list-checkbox input[type="checkbox"] {
  display: none;
}

.list-checkbox input[type="checkbox"]:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #fff;
  border: 1px solid #231815;
  margin-top: -8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
}

.list-checkbox input[type="checkbox"]:after {
  content: "";
  height: 9px;
  opacity: 0;
  width: 5px;
  border-bottom: 3px solid #ed7a9c;
  border-right: 3px solid #ed7a9c;
  margin-top: -7px;
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: rotate(45deg);
}

.list-checkbox input[type="checkbox"]:checked + .checked:after {
  opacity: 1;
}

.list-table {
  width: 100%;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.list-table tr th, .list-table tr td {
  height: 120px;
  background: #f7fdfe;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 28px 10px;
  font-weight: bold;
}

.list-table tr th .small-txt {
  font-size: 13px;
}

.list-table tr td {
  background: #fff;
  padding: 26px 10px;
  font-size: 14px;
  font-weight: normal;
}

.list-table tr td select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.list-table tr td .line-right {
  width: 65%;
  border-right: 1px solid #ccc;
  flex-wrap: wrap;
  margin-right: 10px;
  padding-right: 20px;
  display: flex;
}

.list-table tr td .line-right .tag-button {
  margin: 0 4px 4px 0;
}

.list-table tr td .line-right a {
  display: inline;
}

.list-table tr td.list-table-memo {
  display: table-cell;
}

.list-table tr td .employment-status-tag {
  background: #eee;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
}

.applicants-message-button {
  color: #fff;
  background: #00c4cc;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
}

.list-count {
  display: flex;
}

.list-count li {
  margin-right: 8px;
}

.list-sort {
  margin: 20px 0 75px;
}

.list-sort select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.list-sort .input-txt {
  border: 1px solid #fff8e5;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 16px;
}

.applicant-list-sort {
  grid-template-columns: 100px auto;
  column-gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.applicant-list-sort form {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.applicant-list-sort form .list-sort-item {
  width: 30%;
  margin-bottom: 12px;
}

.wrapp-input {
  grid-gap: 20px;
  grid-template-columns: auto auto auto;
  display: grid;
}

.input-txt, .select-wrapper {
  width: 340px;
  margin-bottom: 0;
  margin-right: 12px;
}

.input-txt select, .select-wrapper select {
  margin-bottom: 0;
}

.job-list .job-list-top-btn, .applicants-list .job-list-top-btn {
  justify-content: flex-end;
}

.applicants-list tr th:first-of-type {
  width: 60px;
}

.applicants-list tr th:nth-of-type(2) {
  width: 130px;
}

.applicants-list tr th:nth-of-type(3), .applicants-list tr th:nth-of-type(4) {
  width: 280px;
}

.applicants-list tr th:nth-of-type(5) {
  width: 220px;
}

.applicants-list tr th:nth-of-type(6) {
  width: 130px;
}

.applicants-list tr th:last-of-type {
  width: 186px;
}

.blank {
  min-width: max-content;
  min-height: 167px;
  background-color: #f2fcfd;
}

.checkbox-selected-item {
  width: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.checkbox-selected-item p {
  font-size: 14px;
  font-weight: bold;
}

.th-sort {
  position: relative;
}

.th-sort .th-sort-arrow {
  margin-left: 8px;
}

.th-sort .th-sort-arrow:before {
  content: "";
  width: 0;
  border-bottom: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 5px;
}

.th-sort .th-sort-arrow:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 14px;
}

.th-sort .th-sort-arrow.th-sort-2row:before {
  top: 15px;
}

.th-sort .th-sort-arrow.th-sort-2row:after {
  top: 26px;
}

.csv-upload {
  border-radius: 16px;
}

.csv-upload .inner {
  max-width: 100%;
  margin: 0 0 20px;
  padding: 40px;
}

.csv-upload .inner .csv-item-desc {
  letter-spacing: .04em;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.csv-upload .inner .upload-item-container {
  align-items: center;
  display: flex;
}

.csv-upload .inner .upload-item-container div {
  width: 100%;
}

.csv-upload .inner .upload-item-container .btn-border-blue {
  color: #00c4cc;
  cursor: pointer;
  letter-spacing: .04em;
  background: #fff;
  border: 1px solid #00c4cc;
  padding: 8px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.csv-upload .inner .upload-item-container .upload-item-content {
  letter-spacing: .04em;
  color: #555;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.csv-upload .inner .upload-item-container .upload-item-desc {
  letter-spacing: .04em;
  color: #aaa;
  margin-top: 4px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.csv-upload .inner .btn-blue-medium {
  width: 200px;
  height: 56px;
  border-radius: 28px;
  bottom: 39px;
  left: calc(50% - 100px);
}

.csv-download {
  border-radius: 16px;
}

.csv-download .inner {
  max-width: 100%;
  margin: 0 auto 20px;
  padding: 40px;
}

.csv-download .inner .inner-header {
  margin-bottom: 20px;
  margin-right: 4px;
  display: inline-block;
}

.csv-download .inner .inner-content {
  grid-template-columns: auto auto 33%;
  gap: 10px;
  display: grid;
}

.csv-download .inner .inner-content input {
  width: 100%;
}

.csv-download .inner .inner-content .select-wrapper {
  width: 100%;
  margin: 0;
}

.csv-download .inner .inner-content .select-wrapper .select-items {
  width: 100%;
  height: 43px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 10px;
}

.csv-download .inner .inner-content .select-wrapper .select-items:before {
  display: none;
}

.csv-download .inner .inner-content .select-wrapper .select-items:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.csv-download .inner .items-center .btn-blue-medium {
  height: 56px;
}

.result-table {
  width: 100%;
}

.result-table .search-message {
  text-align: center;
  letter-spacing: .04em;
  background: #00c4cc33;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  border-radius: 4px !important;
}

.result-table .search-message th {
  text-align: center;
  letter-spacing: .04em;
  color: #555;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.selection-count-text {
  letter-spacing: .04em;
  color: #555;
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.csv-table-result-td {
  line-height: 36px;
}

.csv-table-result-td.job-name, .csv-table-result-td.status-result, .csv-table-result-td.internal-keywords {
  letter-spacing: .04em;
  color: #555;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.csv-upload-image {
  border-radius: 16px;
}

.csv-upload-image .inner {
  max-width: 100%;
  margin: 0 auto 20px;
  padding: 40px;
}

.csv-upload-image .inner .header-container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
}

.csv-upload-image .inner .header-container .desc {
  letter-spacing: .04em;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 24px;
}

.csv-upload-image .inner #upload-image-form .upload-image-container ul li {
  margin-bottom: 10px;
}

.csv-upload-image .inner #upload-image-form .upload-image-container ul li dl {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.csv-upload-image .inner #upload-image-form .upload-image-container ul li dl > :first-child {
  letter-spacing: .04em;
  color: #555;
  margin-right: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.csv-upload-image .inner #upload-image-form .upload-image-container ul li dl :nth-child(2) .btn-border-blue {
  cursor: pointer;
  color: #00c4cc;
  letter-spacing: .04em;
  background: #fff;
  border: 1px solid #00c4cc;
  border-radius: 4px;
  margin-right: 0;
  padding: 8px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.csv-upload-image .inner #upload-image-form .upload-image-container ul li dl :last-child {
  letter-spacing: .04em;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.csv-upload-image .inner #upload-image-form .upload-image-container .upload-note {
  letter-spacing: .04em;
  color: #aaa;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.search-table {
  border: 1px solid #aaa;
  border-radius: 8px;
  grid-template-columns: 30% auto;
  gap: 30px;
  padding: 40px;
  display: grid;
}

.search-table .search-block .inner-content {
  grid-template-columns: auto;
  gap: 10px;
  display: grid;
}

.search-table .search-block .inner-content .input-txt {
  width: 100%;
  height: 43px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 16px;
}

.search-table .search-block .inner-content .select-wrapper {
  width: 100%;
  margin: 0;
}

.search-table .search-block .inner-content .select-wrapper .select-items {
  height: 43px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 10px;
}

.search-table .search-block .inner-content .select-wrapper .select-items:before {
  display: none;
}

.search-table .search-block .inner-content .select-wrapper .select-items:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.search-table .search-block .inner-content .btn-container .btn-border-blue-blod-medium {
  cursor: pointer;
  color: #00c4cc;
  letter-spacing: .04em;
  background: #fff;
  border: 2px solid #00c4cc;
  border-radius: 20px;
  padding: 8px 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.search-table .search-result {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  display: flex;
  overflow: auto;
}

.csv-cropper-container {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.csv-cropper-container .csv-cropper {
  height: 75%;
  position: relative;
}

.csv-cropper-container .csv-cropper .cropper-close-btn {
  z-index: 100;
  min-width: 56px;
  min-height: 56px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.csv-cropper-container .csv-slider {
  width: 40%;
  align-self: center;
  margin-top: 40px;
}

.search-result-item {
  border-bottom: 1px solid #dee2e6;
}

.delete-img-btn, .delete-storage-img-btn {
  width: 28px;
  cursor: pointer;
  position: absolute;
  top: -14px;
  right: -14px;
}

.status-1 {
  width: 60px;
  color: #1b6736;
  text-align: center;
  background-color: #9ce4b6;
  border-radius: 4px;
}

.status-2 {
  width: 60px;
  color: #806a1e;
  text-align: center;
  background-color: #ffe99a;
  border-radius: 4px;
}

.status-3 {
  width: 60px;
  color: #525151;
  text-align: center;
  background-color: #cacaca;
  border-radius: 4px;
}

.csv-update-img {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.csv-update-img button {
  width: 200px;
}

.csv-update-img .csv-footer-text {
  letter-spacing: .04em;
  color: #555;
  margin-top: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.message-item .inner {
  padding: 40px;
}

.message-item .select-item {
  width: 70%;
}

.message-item .message-table-item {
  width: 100%;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
}

.message-item .message-table-item tr th {
  background: #00c4cc08;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
  font-weight: bold;
}

.message-item .message-table-item tr th:nth-child(n+1) {
  width: 25%;
}

.message-item .message-table-item tr th:nth-child(n+2) {
  width: 55%;
}

.message-item .message-table-item tr th:nth-child(n+3) {
  width: 20%;
}

.message-item .message-table-item tr td {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
  font-size: 14px;
}

.message-item .message-table-item tr td .line-right {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 20px;
}

.message-item .message-table-item tr td a {
  display: inherit;
}

.calendar-tool {
  height: 80px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.calendar-tool .calendar-title {
  width: 300px;
}

.report-item .inner {
  width: 100%;
  text-align: left;
  background: #fff;
  border-radius: 16px;
  margin: 40px auto 50px;
  padding: 40px;
  position: relative;
  max-width: 100% !important;
}

.report-item .report-header {
  text-align: left;
}

.report-item .report-header h1 {
  display: flex;
}

.report-item .report-header h1 .icon-qa img {
  margin-bottom: 8px;
}

.report-item .report-header .report-tag {
  width: 100%;
  color: #fe7171;
  text-align: right;
  margin-top: 50px;
  font-size: 20px;
  font-weight: bold;
}

.report-item h2 {
  color: #00c4cc;
  font-size: 16px;
  font-weight: bold;
}

.report-item .report-flex-item {
  display: flex;
}

.report-item .report-flex-item .report-flex-l {
  width: 77%;
  margin-right: 20px;
}

.report-item .report-flex-item .report-flex-r {
  width: calc(23% - 20px);
}

.report-item .report-flex-item .report-flex-r tr {
  border-bottom: 0 !important;
}

.report-item .report-flex-item .report-flex-r tr th {
  text-align: left;
}

.report-item .report-graph-item {
  display: flex;
}

.report-item .report-graph-item .report-graph {
  width: calc(30% - 160px);
  max-width: 278px;
  margin-right: 70px;
}

.report-item .report-graph-item .repot-txt {
  width: 35%;
}

.report-item .report-graph-item .repot-txt-margin {
  margin: 0 80px;
}

.report-item table {
  width: 100%;
  font-size: 14px;
}

.report-item table tr {
  border-bottom: 1px solid #ccc;
}

.report-item table th {
  text-align: center;
  padding: 20px 0;
  font-weight: bold;
}

.report-item table td {
  text-align: center;
  padding: 20px 0;
}

.report-item table .txt-blod {
  font-weight: bold;
}

.report-item .tag-item {
  align-items: center;
  display: flex;
}

.report-item .tag-blue1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #3f94e1;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-red1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #dd7a7a;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-red2:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #dd7a7a99;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-red3:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #dd7a7a33;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-purple1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #cd8bd2;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-purple2:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #cd8bd299;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-purple3:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #cd8bd233;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #3f94e1;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue2:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #3f94e199;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue3:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #3f94e133;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue4:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #86ddd8;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue5:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #86ddd899;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-blue6:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #86ddd833;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-green1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #9dcf75;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-green2:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #9dcf7599;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-green3:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #9dcf7533;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-yellow1:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #e9ec58;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-yellow2:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #e9ec5899;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag-yellow3:after {
  content: "";
  width: 13px;
  height: 13px;
  background: #e9ec5833;
  border-radius: 2px;
  position: absolute;
  transform: translateY(-50%);
}

.report-item .tag {
  margin-right: 20px;
}

.report-item .txt-left {
  font-weight: bold;
  text-align: left !important;
}

.processes-item h1 span {
  color: #aaa;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 300;
}

.processes-item .inner {
  padding: 40px;
}

.processes-item ul {
  border-top: 1px solid #ccc;
}

.processes-item ul li {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  font-size: 16px;
}

.results-item {
  width: 100%;
  max-width: 1280px;
  text-align: left;
  background: #fff;
  border-radius: 16px;
  margin-top: 20px;
  padding: 40px;
  position: relative;
}

.results-item textarea {
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 8px 10px;
  font-size: 16px;
}

.results-item textarea:focus {
  color: #555;
  border: 1px solid #40a9ff;
  border-radius: 4px;
  outline: none;
  padding: 8px 10px;
  font-size: 16px;
}

.results-item .colse2 {
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
}

.results-item th:nth-child(n+1), .results-item th:nth-child(n+2) {
  width: 17% !important;
}

.results-item th:nth-child(n+3) {
  width: 56% !important;
}

.results-item th:nth-child(n+4) {
  text-align: center;
  width: 10% !important;
}

.results-item td textarea {
  height: 120px;
  margin-bottom: 0;
}

.results-item td .txt-link-gray {
  margin-top: 0 !important;
}

.results-item td:nth-child(n+4) {
  text-align: center;
}

.results-item:first-child {
  margin-top: 0;
}

.results-h1-item {
  width: 100%;
  max-width: 1360px;
  margin: 40px auto 0;
  display: flex;
}

.tab-detail-applicant {
  width: 100%;
  margin: 40px auto 0;
  display: flex;
}

.results-h1-on {
  color: #00c4cc;
  width: fit-content;
  background: #e6fafa;
  border-radius: 8px 8px 0 0;
  margin: 0 10px 0 0;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
}

.results-h1-off {
  color: #555;
  width: fit-content;
  background: #fff;
  border-radius: 8px 8px 0 0;
  margin: 0 10px 0 0;
  padding: 20px;
  font-size: 18px;
}

.results-h1-off a {
  color: #555;
}

.results-ttl-item {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: flex;
}

.results-ttl-item .results-ttl {
  width: calc(100% - 260px);
  font-size: 16px;
  font-weight: bold;
}

.results-ttl-item .results-img {
  width: calc(100% - 260px);
}

.results-ttl-item .results-btn {
  width: 30%;
  max-width: 260px;
  justify-content: flex-end;
  display: flex;
}

.results-ttl-item .results-btn a {
  margin-left: 10px;
}

.results-ttl-item .results-btn a img {
  width: 25px;
}

.results-flex-item {
  display: flex;
}

.results-flex-item ul {
  width: 45%;
}

.results-flex-item .results-flex-btn {
  margin-left: 40px;
}

.results-list li {
  padding: 10px 0;
}

.results-list li dl {
  font-size: 14px;
  display: flex;
}

.results-list li dl dt {
  width: 20%;
  min-width: 230px;
  display: flex;
}

.results-list li dl dd {
  width: 80%;
}

.results-list li dl dd .flex-box {
  display: flex;
}

.results-list li dl dd .flex-box .flex-box-ttl {
  width: 20%;
  min-width: 100px;
}

.results-list li dl dd .flex-box .flex-box-txt {
  width: 80%;
}

.results-list li dl dd .flex-box:first-child {
  margin-bottom: 10px;
}

.results-list li dl dd .btn-blue-small {
  margin-right: 10px;
}

.results-list li dl dd p {
  align-items: center;
  display: flex;
}

.results-list li dl dd p span {
  padding: 8px 10px 8px 0;
}

.results-list li dl dd input, .results-list li dl dd textarea {
  margin-bottom: 0;
}

.results-list li dl dd select {
  width: 100%;
}

.results-list li dl dd textarea {
  height: 120px;
}

.results-list li dl dd .txt-link-blue {
  color: #00c4cc;
  margin-bottom: 10px;
}

.results-list li dl dd .txt-link-gray {
  color: #ccc;
  margin-top: 5px;
}

.results-list li dl dd .img-up-item {
  max-width: 200px;
  margin-top: 10px;
  position: relative;
}

.results-list li dl dd .img-up-item .img img {
  width: 100%;
  object-fit: cover;
  max-width: 200px;
  height: auto;
}

.results-list li dl dd .img-up-item .colse {
  z-index: 6666;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
}

.table-item {
  width: 100%;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.table-item tr th {
  background: #f7fdfe;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 30px 10px;
  font-weight: bold;
}

.table-item tr th:first-child, .table-item tr th:nth-child(n+1) {
  width: 33%;
}

.table-item tr th:nth-child(n+3), .table-item tr th:nth-child(n+4) {
  width: 17%;
}

.table-item tr td {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 30px 10px;
}

.table-item tr td .line-right {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 20px;
}

.table-item tr td a {
  display: inherit;
}

.right-btn-item {
  width: 100%;
  max-width: 1280px;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0 20px auto;
  display: flex;
}

.right-btn-item input {
  margin-left: 10px;
}

.toggle-button-cover {
  width: 260px;
  position: relative;
}

.button-cover, .knobs, .layer {
  position: absolute;
  inset: 0;
}

.button {
  width: 260px;
  height: 36px;
  margin: -20px auto 0;
  position: relative;
  top: 50%;
  overflow: hidden;
}

.button input[type="checkbox"] {
  display: block !important;
}

.button.r, .button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
  margin: 0;
  padding: 0;
  position: relative;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  z-index: 1;
  background-color: #00c4cc1a;
  transition: all .3s;
}

#button-18 .knobs:before, #button-18 .knobs span {
  content: "ユーザー画面で表示中";
  color: #fff;
  text-align: center;
  background-color: #00c4cc;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 4px;
  left: 4px;
}

#button-18 .knobs:before {
  width: 180px;
  height: 28px;
  z-index: 2;
  background-color: #0000;
  margin-top: -5px;
  top: 50%;
  left: 1px;
}

#button-18 .knobs span {
  width: 170px;
  height: 28px;
  z-index: 1;
  padding: 9px 4px;
  transition: all .3s, left .3s cubic-bezier(.18, .89, .35, 1.15);
}

#button-18 .checkbox:active + .knobs:before {
  width: 60px;
  height: 4px;
  color: #0000;
  background-color: #00c4cc;
  margin-top: -2px;
  transition: all .3s;
  left: 10px;
  overflow: hidden;
}

#button-18 .checkbox:active + .knobs span {
  width: 260px;
}

#button-18 .checkbox:checked:active + .knobs:before {
  background-color: #fe7171;
  left: auto;
  right: 10px;
}

#button-18 .checkbox:checked:active + .knobs span {
  margin-left: 0;
}

#button-18 .checkbox:checked + .knobs:before {
  content: "ユーザー画面で非表示中";
  left: 82px;
}

#button-18 .checkbox:checked + .knobs span {
  background-color: #fe7171;
  left: 86px;
}

#button-18 .checkbox:checked ~ .layer {
  background-color: #fe717133;
}

.set-password-item {
  margin-bottom: 30px;
}

.set-password-item dt, .set-password-item dd {
  text-align: left;
}

.set-password-item dt .input-bg-yellow, .set-password-item dd .input-bg-yellow {
  width: 100%;
}

.set-password-item dt .input-bg-yellow button, .set-password-item dd .input-bg-yellow button {
  position: absolute;
  bottom: 6px;
  right: 2px;
}

.set-password-item dt {
  font-weight: bold;
}

.set-password-item .error {
  color: red;
}

.boder-error {
  border: 2px solid red;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 10px 0;
}

.boder-error .error {
  color: red;
}

.password-item {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px auto 40px;
  padding: 20px;
  display: flex;
}

.password-item dt {
  min-width: 250px;
  padding: 8px 20px 8px 0;
}

.password-item dd {
  width: inherit;
}

.app-logo {
  margin-bottom: 60px;
}

.app-logo img {
  max-width: 242px;
}

.css-a88p61-MuiInputBase-root-MuiInput-root:before, .css-a88p61-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

.forgot-password-container {
  width: 900px;
  height: 196px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 32px 24px 0 !important;
}

.forgot-password-container .close-btn {
  min-width: 56px;
  min-height: 56px;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -28px;
  right: 30px;
  box-shadow: 0 0 8px #0000001a;
  z-index: 10000000 !important;
}

.forgot-password-container .forgot-password-title {
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.forgot-password-container .forgot-password-title .forgot-password-content {
  color: #00c4cc;
  padding-left: 10px;
  font-size: 32px;
  line-height: 0 !important;
}

.forgot-password-container .forgot-password-des {
  margin-top: 20px;
  font-size: 16px;
}

.forgot-password-list {
  margin-top: 20px;
}

.forgot-password-list li {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.forgot-password-list li:first-child {
  border-top: 1px solid #ccc;
}

.forgot-password-list li dl {
  font-size: 14px;
  display: flex;
}

.forgot-password-list li dl dt {
  width: 20%;
  min-width: 300px;
  align-items: center;
  display: flex;
}

.forgot-password-list li dl dd {
  width: 552px;
}

.forgot-password-list li dl dd .flex-box {
  display: flex;
}

.forgot-password-list li dl dd .flex-box .flex-box-ttl {
  width: 20%;
  min-width: 100px;
}

.forgot-password-list li dl dd .flex-box .flex-box-txt {
  width: 80%;
}

.forgot-password-list li dl dd .flex-box:first-child {
  margin-bottom: 10px;
}

.forgot-password-list li dl dd .btn-blue-small {
  margin-right: 10px;
}

.forgot-password-list li dl dd p {
  align-items: center;
  display: flex;
}

.forgot-password-list li dl dd p span {
  padding: 8px 10px 8px 0;
}

.forgot-password-list li dl dd input, .forgot-password-list li dl dd textarea {
  margin-bottom: 0;
}

.forgot-password-list li dl dd select {
  width: 100%;
}

.forgot-password-list li dl dd textarea {
  height: 120px;
}

.forgot-password-list li dl dd .txt-link-blue {
  color: #00c4cc;
  margin-bottom: 10px;
}

.forgot-password-list li dl dd .img-up-item {
  max-width: 200px;
  margin-top: 10px;
  position: relative;
}

.forgot-password-list li dl dd .img-up-item .img img {
  width: 100%;
  object-fit: cover;
  max-width: 200px;
  height: auto;
}

.forgot-password-list li dl dd .img-up-item .colse {
  z-index: 6666;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: -20px;
}

.forgot-password-list li .description {
  letter-spacing: .04em;
  color: #aaa;
  margin-left: 300px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.forgot-password-title-button {
  height: 219px;
  border-bottom-left-radius: 16px;
}

.forgot-password-title-button .button-container {
  justify-content: center;
  display: flex;
}

.help-container {
  max-width: 1360px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 40px;
  display: block;
}

.help-container .help-inner-content {
  flex-direction: column;
  align-items: center;
  display: block;
}

.help-container .help-inner-content .search-bar-container {
  height: 134px;
  background: #fff;
  border-radius: 8px;
}

.help-container .help-inner-content .search-bar-container .search-bar-content {
  width: 900px;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
  display: flex;
  position: relative;
}

.help-container .help-inner-content .search-bar-container .search-bar-content .search-bar {
  height: 54px;
  letter-spacing: .04em;
  color: #aaa;
  background: #fabe001a;
  border: none;
  border-radius: 27px;
  margin-bottom: 0;
  padding-left: 30px;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.help-container .help-inner-content .search-bar-container .search-icon {
  position: absolute;
  top: 34%;
  right: 2%;
}

.help-container .help-inner-content .help-content {
  max-width: 900px;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
  display: flex;
}

.help-container .help-inner-content .help-content .help-list {
  width: 100%;
  margin-bottom: 50px;
}

.help-container .help-inner-content .help-content .help-list .list-title {
  letter-spacing: .04em;
  color: #555;
  margin-bottom: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.help-container .help-inner-content .help-content .help-list .help-item {
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
}

.help-container .help-inner-content .help-content .help-list .help-item .help-item-content {
  border-top: 1px solid #ccc;
  margin: 0 30px;
}

.help-container .help-inner-content .help-content .help-list .help-item dl dt {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.help-container .help-inner-content .help-content .help-list .help-item dl dt .item-title {
  letter-spacing: .04em;
  color: #555;
  margin-left: 30px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.help-container .help-inner-content .help-content .help-list .help-item dl dt .item-icon {
  margin-right: 30px;
}

.company-item {
  max-width: 1536px;
  margin: 40px auto;
}

.company-item .inner {
  margin-top: 0;
  padding: 40px;
}

.company-item .select-item {
  width: 70%;
}

.company-item .company-table-item {
  width: 100%;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
}

.company-item .company-table-item tr th {
  background: #00c4cc08;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
  font-weight: bold;
}

.company-item .company-table-item tr th:nth-child(n+1) {
  width: 30%;
}

.company-item .company-table-item tr th:nth-child(n+2) {
  width: 20%;
}

.company-item .company-table-item tr th:nth-child(n+3) {
  width: 35%;
}

.company-item .company-table-item tr th:nth-child(n+4) {
  width: 15%;
}

.company-item .company-table-item tr td {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 30px 10px;
  font-size: 14px;
}

.company-item .company-table-item tr td .line-right {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding-right: 20px;
}

.company-item .company-table-item tr td a {
  display: inherit;
}

.policy-wraper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.policy-wraper .policy-title {
  letter-spacing: .04em;
  color: #00c4cc;
  margin: 32px 0;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
}

.policy-wraper .policy-content {
  max-width: 1200px;
  letter-spacing: .04em;
  color: #555;
  margin-bottom: 60px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

#preview-header {
  box-sizing: content-box;
  --tw-bg-opacity: 1;
  background-color: rgb(0 196 204 / var(--tw-bg-opacity));
  height: 60px;
  padding: 0;
}

#preview-header .header-container {
  width: 960px;
  max-width: 960px;
  height: 60px;
  margin: 0 auto;
  padding: 0 1.25rem;
}

#preview-header .header-container .header-content {
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

#preview-header .header-container .header-content .company-name {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
}

#preview-header .header-container .header-content .company-name .company-name-text1 {
  letter-spacing: .04em;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

#preview-header .header-container .header-content .company-name .company-name-text2 {
  letter-spacing: .1em;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

#preview-header .header-container .header-content .company-job-info {
  flex-direction: column;
  justify-items: center;
  display: flex;
}

#preview-header .header-container .header-content .company-job-info .job-info-logo {
  justify-content: center;
  display: flex;
}

#preview-header .header-container .header-content .company-job-info .job-info-description {
  letter-spacing: .04em;
  color: #fcdf80;
  font-family: Noto Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.inner-container {
  max-width: 960px;
  margin: auto;
  position: relative;
}

.body-container {
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.body-container .company-banner {
  width: 100%;
}

.body-container .company-banner img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.body-container .company-menu-content {
  width: 100%;
  background: #00c4cc26;
}

.body-container .company-menu-content .company-first-slogan {
  text-align: center;
  letter-spacing: .2em;
  color: #555;
  margin-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.body-container .company-menu-content .company-menu-box {
  background: #fff;
  border-radius: 8px;
  margin: 20px 40px 30px;
  padding-bottom: 30px;
}

.body-container .company-menu-content .company-menu-box .menu-box-title {
  text-align: center;
  letter-spacing: .4em;
  color: #555;
  padding-top: 20px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.body-container .company-menu-content .company-menu-box .menu-box-item {
  border-bottom: 1px solid #00c4cc;
  margin: 20px 40px 0;
}

.body-container .company-menu-content .company-menu-box .menu-box-item .menu-box-item-content {
  letter-spacing: .2em;
  color: #555;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none;
  display: flex;
}

.body-container .basic-design-container {
  width: 100%;
  background-color: #fff;
  padding-bottom: 10px;
}

.body-container .basic-design-container .basic-design-title {
  text-align: center;
  letter-spacing: .2em;
  color: #00c4cc;
  margin-top: 80px;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.body-container .basic-design-container .basic-design-text {
  text-align: center;
  letter-spacing: .04em;
  color: #555;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.body-container .basic-design-container .basic-design-images-wrapper .basic-design-image {
  width: 92%;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.body-container .image-design-container {
  width: 100%;
  background-color: #fff;
  padding-bottom: 10px;
}

.body-container .image-design-container .image-design-title {
  text-align: center;
  letter-spacing: .2em;
  color: #00c4cc;
  margin-top: 80px;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.body-container .image-design-container .image-design-images-wrapper .image-design-img {
  width: 92%;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.body-container .image-design-container .image-design-images-wrapper .image-description {
  letter-spacing: .04em;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.body-container .preview-job-list {
  width: 100%;
  background: #00c4cc;
}

.body-container .preview-job-list .preview-job-list-title {
  text-align: center;
  letter-spacing: .2em;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.body-container .preview-job-list .preview-job-list-status .list-status-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.body-container .preview-job-list .preview-job-list-status li {
  margin-bottom: 10px;
  margin-right: 20px;
}

.body-container .preview-job-list .preview-job-list-status li dl {
  align-items: center;
}

.body-container .preview-job-list .preview-job-list-status li dl .status-title {
  letter-spacing: .04em;
  color: #fff;
  min-width: 60px;
  margin: 0 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.body-container .preview-job-list .preview-job-list-status li dl .status-select-wrapper {
  position: relative;
}

.body-container .preview-job-list .preview-job-list-status li dl .status-select-wrapper:after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 21px;
  right: 9px;
}

.body-container .preview-job-list .preview-job-list-status li dl .status-select {
  width: 100%;
  height: 40px;
  background: #fff8e5;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
}

.body-container .preview-job-list .preview-job-list-content li {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px;
}

.body-container .preview-job-list .preview-job-list-content li .job-img-wrapper {
  margin: 20px 10px 20px 20px;
}

.body-container .preview-job-list .preview-job-list-content li .job-img-wrapper .job-img {
  max-width: 90px;
}

.body-container .preview-job-list .preview-job-list-content li dd {
  margin-top: 20px;
  margin-bottom: 20px;
}

.body-container .preview-job-list .preview-job-list-content li dd .job-title {
  letter-spacing: .04em;
  color: #00c4cc;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.body-container .preview-job-list .preview-job-list-content li dd .job-tag, .body-container .preview-job-list .preview-job-list-content li dd .job-info-data {
  letter-spacing: .04em;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.body-container .preview-job-list .preview-job-list-content li dd .job-info {
  width: 60px;
  letter-spacing: .04em;
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.selection-flow-container {
  width: 100%;
  background-color: #fff;
}

.selection-flow-container .selection-flow-title {
  text-align: center;
  letter-spacing: .2em;
  color: #00c4cc;
  margin: 80px 0 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item {
  margin-bottom: 20px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item dt {
  min-width: 32px;
  height: 32px;
  text-align: center;
  color: #fff;
  background: #00c4cc;
  border-radius: 4px;
  margin: 0 10px 0 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .item-title {
  letter-spacing: .04em;
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .item-description {
  letter-spacing: .04em;
  color: #555;
  margin-right: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .has-line {
  justify-content: center;
  margin-top: 20px;
  display: flex;
  position: relative;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .has-line:before, .selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .has-line:after {
  content: "";
  width: 45%;
  height: 2px;
  opacity: .5;
  background-color: #00c4cc80;
  position: absolute;
  top: 2px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .has-line:before {
  left: 20px;
}

.selection-flow-container .selection-flow-content .selection-flow-list .selection-flow-item .has-line:after {
  right: 20px;
}

.company-info-container {
  width: 100%;
  background: #777;
  padding-bottom: 30px;
}

.company-info-container .company-logo-wrapper {
  text-align: center;
  margin: 40px 0;
}

.company-info-container .company-logo-wrapper .company-logo {
  height: 80px;
}

.company-info-container .company-info-list .company-info-item {
  margin-bottom: 10px;
}

.company-info-container .company-info-list .company-info-item .company-info-title {
  letter-spacing: .04em;
  min-width: 70px;
  color: #fff;
  max-height: 21px;
  border-left: 2px solid #00c4cc;
  margin: 0 20px;
  padding-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.company-info-container .company-info-list .company-info-item .company-info-detail {
  letter-spacing: .04em;
  color: #fff;
  margin-right: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.copyright-container {
  width: 100%;
  height: 85px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.copyright-container .copyright-content {
  text-align: center;
  letter-spacing: .04em;
  color: #555;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.rec.rec-arrow {
  min-width: 30px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
  line-height: 30px;
  box-shadow: 0 0 8px #0000001a;
}

.rec.rec-arrow-left {
  z-index: 1;
  position: absolute;
  left: 6px;
}

.rec.rec-arrow-right {
  z-index: 1;
  position: absolute;
  right: 6px;
}

.rec.rec-arrow:hover:enabled, .rec.rec-arrow:focus:enabled {
  border: 1px solid #00c4cc;
  background-color: #00c4cc !important;
}

.rec.rec-item-wrapper .image-design-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.rec.rec-dot {
  background-color: #ccc;
  box-shadow: none !important;
}

.rec-dot_active {
  box-shadow: none !important;
  background-color: #555 !important;
}

#job-preview-header {
  box-sizing: content-box;
  --tw-bg-opacity: 1;
  background-color: rgb(0 196 204 / var(--tw-bg-opacity));
  height: 70px;
  padding: 0;
}

#job-preview-header .header-container {
  width: 1000px;
  max-width: 1000px;
  height: 60px;
  margin: 0 auto;
  padding: 0 20px;
}

#job-preview-header .header-container .header-content {
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

#job-preview-header .header-container .header-content .company-name {
  width: 180px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
}

#job-preview-header .header-container .header-content .company-name .company-name-text1 {
  letter-spacing: .04em;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

#job-preview-header .header-container .header-content .company-name .company-name-text2 {
  letter-spacing: .1em;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.job-banner {
  width: 100%;
}

.job-banner img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.job-tag-name-container {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 15px;
}

.job-tag-name-container .job-name {
  letter-spacing: .04em;
  color: #00c4cc;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.job-tag-name-container .job-tag-container {
  flex-wrap: wrap;
  display: flex;
}

.job-tag-name-container .job-tag-container .job-tag {
  letter-spacing: .04em;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 4px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.contact-container {
  z-index: 10;
  width: 100%;
  background: linear-gradient(0deg, #777, #777), #fff;
  position: fixed;
  bottom: 0;
}

.contact-container .contact-inner-content {
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
}

.contact-container .contact-inner-content .phone-contact {
  height: 70px;
  width: 49%;
  min-width: 164px;
  cursor: pointer;
  background: linear-gradient(0deg, #fabe00, #fabe00), #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.contact-container .contact-inner-content .phone-contact .phone-contact-icon {
  color: #fff;
  margin-right: 4px;
}

.contact-container .contact-inner-content .phone-contact .phone-contact-text {
  letter-spacing: .04em;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.contact-container .contact-inner-content .web-contact {
  min-width: 164px;
  width: 49%;
  height: 70px;
  cursor: pointer;
  background: linear-gradient(0deg, #00c4cc, #00c4cc), #fff;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.contact-container .contact-inner-content .web-contact .web-contact-title {
  letter-spacing: .04em;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.contact-container .contact-inner-content .web-contact .web-contact-description {
  letter-spacing: .04em;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.job-slogan-container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 40px;
}

.job-slogan-container .job-slogan-copy {
  letter-spacing: .04em;
  color: #555;
  white-space: pre-line;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.job-slogan-container .job-slogan-read {
  letter-spacing: .04em;
  color: #555;
  white-space: pre-line;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.preview-body-container {
  background: #00c4cc1a;
  padding: 40px 0;
}

.preview-body-container .job-description-container {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px 0;
}

.preview-body-container .job-description-container .job-description {
  white-space: pre-line;
  margin: 16px 20px 0;
}

.preview-body-container .job-condition-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 0;
}

.preview-body-container .job-condition-container .job-condition-content {
  letter-spacing: .04em;
  color: #555;
  white-space: pre-line;
  margin: 16px 20px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.preview-body-container .job-image-container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.preview-body-container .job-image-container .job-preview-image {
  width: 96%;
  border-radius: 8px;
}

.preview-body-container .job-image-container .job-image-description {
  text-align: center;
  letter-spacing: .04em;
  color: #555;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.preview-body-container .job-details-container {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px 0;
}

.preview-body-container .job-details-container .job-details-content {
  margin-top: 16px;
}

.preview-body-container .job-details-container .job-details-content .job-detail {
  margin: 10px 20px 0;
}

.preview-body-container .job-details-container .job-details-content .job-detail .job-detail-title {
  letter-spacing: .04em;
  color: #555;
  border-left: 2px solid #00c4cc;
  margin-bottom: 4px;
  padding-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.preview-body-container .job-details-container .job-details-content .job-detail .job-detail-description {
  letter-spacing: .04em;
  color: #555;
  white-space: pre-line;
  padding-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.preview-body-container .job-otherinfo-container {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px 0;
}

.preview-body-container .job-otherinfo-container .job-otherinfo-content {
  margin: 16px 20px 0;
}

.preview-body-container .job-otherinfo-container .job-otherinfo-content .otherinfo-title {
  letter-spacing: .04em;
  color: #555;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.preview-body-container .job-otherinfo-container .job-otherinfo-content .otherinfo-content {
  letter-spacing: .04em;
  color: #555;
  white-space: pre-line;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.preview-body-container .job-location-container {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px 0;
}

.preview-body-container .job-location-container .job-location-content {
  margin: 16px 20px 0;
}

.preview-body-container .job-location-container .job-location-content .job-location {
  letter-spacing: .04em;
  color: #555;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.preview-body-container .job-location-container .job-location-content .job-location-map {
  height: 300px;
}

.job-slider-container {
  background-color: #fff;
  padding: 40px 0;
}

.job-slider-container .job-list-slider-container .job-slider-item {
  max-width: 180px;
  min-height: 235px;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 0 8px #0000001a;
}

.job-slider-container .job-list-slider-container .job-slider-item .job-item-inner-content {
  padding: 20px;
  align-items: flex-start !important;
}

.job-slider-container .job-list-slider-container .job-slider-item .job-item-inner-content .job-item-image {
  height: 100px;
  width: 140px;
}

.job-slider-container .job-list-slider-container .job-slider-item .job-item-inner-content .job-item-name {
  letter-spacing: .04em;
  color: #00c4cc;
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.job-slider-container .job-list-slider-container .job-slider-item .job-item-inner-content .job-item-workplace {
  letter-spacing: .04em;
  color: #aaa;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  display: flex;
}

.applicant-form-container {
  background-color: #00c4cc;
  padding: 40px 0;
}

.applicant-form-container .applicant-form-title {
  text-align: center;
  letter-spacing: .2em;
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.applicant-form-container .applicant-inner-content {
  max-width: 960px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 30px 20px 20px;
  position: relative;
}

.applicant-form-container .applicant-inner-content .applicant-text {
  letter-spacing: .04em;
  color: #00c4cc;
  margin-top: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .item-title {
  letter-spacing: .04em;
  color: #555;
  margin-bottom: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom {
  cursor: pointer;
  letter-spacing: .04em;
  color: #555;
  user-select: none;
  margin-right: 40px;
  padding-left: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  position: relative;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom .checkmark-custom {
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 0;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom .checkmark-custom:after {
  content: "";
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom:hover input ~ .checkmark-custom {
  background-color: #ccc;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom input:checked ~ .checkmark-custom {
  background-color: #2196f3;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom input:checked ~ .checkmark-custom:after {
  display: block;
}

.applicant-form-container .applicant-inner-content .applicant-form-list .applicant-form-item .applicant-checkbox .applicant-checkbox-custom input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.copy-right-container {
  text-align: center;
  letter-spacing: .04em;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.job-img-inner-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 6px;
  position: relative;
}

.job-inner-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.job-preview-logo {
  text-align: center;
  letter-spacing: .04em;
  color: #00c4cc;
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}

.job-preview-title {
  text-align: center;
  letter-spacing: .2em;
  color: #00c4cc;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.color-white {
  color: #fff;
}

.color-blue {
  color: #00c4cc;
}

.color-gray {
  color: #ccc;
}

.color-font1-gray {
  color: #aaa;
}

.color-yellow {
  color: #40a9ff;
}

.color-black {
  color: #555;
}

.bg-blue {
  background-color: #00c4cc;
}

.bg-light-blue {
  background-color: #f2fcfd;
}

.bg-white {
  background-color: #fff;
}

.d-n {
  display: none;
}

.d-i {
  display: inline;
}

.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.d-tb {
  display: table;
}

.d-tbc {
  display: table-cell;
}

.d-tbr {
  display: table-row;
}

.d-f {
  display: flex;
}

.d-if {
  display: inline-flex;
}

.display-t {
  width: 100%;
  display: table;
}

@media screen and (max-width: 575px) {
  .sp-d {
    display: block;
  }

  .tb-d, .pc-d {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp-d, .tb-d {
    display: block;
  }

  .pc-d {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sp-d, .tb-d {
    display: none;
  }

  .pc-d {
    display: block;
  }
}

.t-layout-f {
  table-layout: fixed;
}

.fxw-w {
  flex-wrap: wrap;
}

.fl-d {
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-ct {
  justify-content: center;
}

.ai-c {
  align-items: center;
}

.ai-b {
  align-items: baseline;
}

.f-1 {
  flex: 1;
}

.va-t {
  vertical-align: top;
}

.va-m {
  vertical-align: middle;
}

.pos-a {
  position: absolute;
}

.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.sticky {
  z-index: 1000;
  position: sticky;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.clear:after {
  content: "";
  clear: both;
  display: block;
}

.ov-h {
  overflow: hidden;
}

.m-0 {
  margin: 0 !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mlr-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mlr-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mlr-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mlr-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mlr-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mlr-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mlr-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mlr-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mlr-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mlr-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mlr-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mtb-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mtb-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mtb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mtb-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mtb-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mtb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mtb-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mtb-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mtb-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mtb-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mtb-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.plr-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.plr-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.plr-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.plr-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.plr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.plr-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.plr-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.plr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.plr-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ptb-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ptb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ptb-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ptb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.ptb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ptb-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.ptb-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.ptb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.w-40 {
  width: 40px;
}

.w-60 {
  width: 60px;
}

.w-88 {
  width: 88px;
}

.w-150 {
  width: 150px;
}

.w-10per {
  width: 10% !important;
}

.w-15per {
  width: 15% !important;
}

.w-20per {
  width: 20% !important;
}

.w-25per {
  width: 25% !important;
}

.w-30per {
  width: 30% !important;
}

.w-40per {
  width: 40% !important;
}

.w-45per {
  width: 45% !important;
}

.w-50per {
  width: 50% !important;
}

.w-55per {
  width: 55% !important;
}

.w-60per {
  width: 60% !important;
}

.w-65per {
  width: 65% !important;
}

.w-70per {
  width: 70% !important;
}

.w-80per {
  width: 80% !important;
}

.w-85per {
  width: 85% !important;
}

.w-90per {
  width: 90% !important;
}

.w-95per {
  width: 95% !important;
}

.w-100per {
  width: 100% !important;
}

.mw-100per {
  max-width: 100%;
}

.bd-n {
  border: none;
}

.border-gray-a {
  border: 1px solid #aaa;
}

.bdrs-4 {
  border-radius: 4px;
}

.bdrs-16 {
  border-radius: 16px;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.text_0ABAB5 {
  color: #0abab5;
}

.normal {
  font-weight: normal;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.drop-shadow-white {
  filter: drop-shadow(0 0 2px #fff);
}

.drop-shadow-black {
  filter: drop-shadow(0 0 4px #00000080);
}

.list-style-position-i {
  list-style-position: inside;
}

.pl1em-ti1em {
  text-indent: -1em;
  padding-left: 1em;
}

.v-align-middle {
  vertical-align: middle;
}

.z-idx-0 {
  z-index: 0;
}

.z-idx-1 {
  z-index: 1;
}

.z-idx-2 {
  z-index: 2;
}

.z-idx-3 {
  z-index: 3;
}

.z-idx-4 {
  z-index: 4;
}

.z-idx-5 {
  z-index: 5;
}

.z-idx-10 {
  z-index: 10;
}

.z-idx-20 {
  z-index: 20;
}

.z-idx-30 {
  z-index: 30;
}

.z-idx-50 {
  z-index: 50;
}

.z-idx-100 {
  z-index: 100;
}

.z-idx-9999 {
  z-index: 9999;
}

.touch-action-m {
  touch-action: manipulation;
}

.scale-tr-72 {
  transform-origin: 100% 0;
  font-size: 1.6rem;
  line-height: 1;
  transform: scale(.72);
}

.cur-p {
  cursor: pointer;
}

.cur-p:hover {
  opacity: .8;
}

.cur-d {
  cursor: default;
}

.dialogEdit {
  text-align: center;
  margin-top: 20px;
}

.btn-img {
  width: 33.33%;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
}

.btn-img .img {
  width: 220px;
  height: 170px;
}

.btn-btn {
  position: absolute;
  inset: 0;
}

.addContent {
  justify-content: end;
  display: flex;
}

.img {
  position: relative;
}

.img-input-file {
  opacity: 0;
  position: absolute;
  top: 0;
}

.color-error {
  color: #fe7171;
}

.dialog {
  width: 100vh;
  z-index: 1;
  background-color: #fff;
  padding: 50px;
  inset: 0;
}

.dialog .dialog-title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.dialog .dialog-title .dialog-title-action {
  align-items: center;
  display: flex;
}

.dialog .dialog-title .dialog-title-action .dialog-title-text {
  font-size: 16px;
  font-weight: 600;
}

.dialog .dialog-title .dialog-title-action .dialog-title-btn {
  color: #fff;
  background-color: #2285ef;
  border-radius: 20px;
  align-items: center;
  margin: 0 20px;
  padding: 5px 20px;
  font-size: 17px;
  display: flex;
}

.dialog .dialog-title .dialog-title-action .dialog-title-select {
  align-items: center;
  font-size: 14px;
  display: flex;
}

.dialog .dialog-title .dialog-title-action .dialog-title-select .dialog-title-link {
  color: #2285ef;
  text-decoration: none;
}

.dialog .dialog-content {
  width: 100%;
  box-sizing: border-box;
}

.dialog .dialog-content .row {
  flex-wrap: wrap;
  margin: 0 -6px;
  display: flex;
}

.dialog .dialog-content .row .col {
  flex: 0 0 12.5%;
  margin-top: 20px;
  margin-right: 10px;
  position: relative;
}

.dialog_antd {
  z-index: 1;
  background-color: #fff;
  padding: 50px;
  inset: 0;
}

.dialog_antd .dialog-title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.dialog_antd .dialog-title .dialog-title-action {
  align-items: center;
  display: flex;
}

.dialog_antd .dialog-title .dialog-title-action .dialog-title-text {
  font-size: 16px;
  font-weight: 600;
}

.dialog_antd .dialog-title .dialog-title-action .dialog-title-btn {
  color: #fff;
  border-radius: 20px;
  align-items: center;
  margin: 0 20px;
  padding: 5px 20px;
  font-size: 17px;
  display: flex;
  background-color: #2285ef !important;
}

.dialog_antd .dialog-title .dialog-title-action .dialog-title-select {
  align-items: center;
  font-size: 14px;
  display: flex;
}

.dialog_antd .dialog-title .dialog-title-action .dialog-title-select .dialog-title-link {
  color: #2285ef;
  text-decoration: none;
}

.dialog_antd .dialog-content {
  width: 100%;
  box-sizing: border-box;
}

.dialog_antd .dialog-content .row {
  flex-wrap: wrap;
  margin: 0 -6px;
  display: flex;
}

.dialog_antd .dialog-content .row .col {
  flex: 0 0 12.5%;
  margin-top: 20px;
  margin-right: 10px;
  position: relative;
}

.list-job-table .table-cell-head {
  padding: 40px 12px;
}

.list-job-table .table-cell-jobTitle {
  width: 400px;
}

.list-job-table .table-cell-indeed, .list-job-table .table-cell-category, .list-job-table .table-cell-status, .list-job-table .table-cell-listed-company-name {
  width: max-content;
}

.list-job-table .table-cell-posted-date, .list-job-table .table-cell-update-day {
  width: 160px;
}

.list-job-table .table-cell-action {
  width: 190px;
}

.list-job-table .table-cell-municipalities {
  width: 100px;
}

.action-btn {
  color: #fff;
  letter-spacing: .04em;
  text-align: center;
  background: #00c4cc;
  border-radius: 4px;
  padding: 0 8px;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.action-btn:hover {
  color: #fcdf80;
}

.action-box {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.btn-group-2 {
  margin-bottom: 4px;
}

.btn-full {
  width: 100%;
}

.group-btn-left {
  position: relative;
}

.group-btn-left:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #ccc;
  position: absolute;
  top: 0;
  right: -15px;
}

.list-applicant-table .table-cell-head {
  padding: 40px 12px;
}

.applicant-button {
  color: #fff !important;
  width: 100% !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: 4px !important;
  padding: 4px 15px !important;
  display: block !important;
}

.applicant-btn-chat {
  background: #00c4cc !important;
}

.applicant-btn-info {
  background: #ffcc17;
}

/*# sourceMappingURL=index.113cd36c.css.map */
