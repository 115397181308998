@import 'variable';

//color
.color-white {
  color: $color-white;
}

.color-blue {
  color: $color-blue;
}

.color-gray {
  color: $color-gray;
}

.color-font1-gray {
  color: $color-font1-gray;
}

.color-yellow {
  color: $color-yellow;
}

.color-black {
  color: $color-black;
}

//background-color
.bg-blue {
  background-color: $color-blue;
}

.bg-light-blue {
  background-color: $color-light-blue;
}

.bg-white {
  background-color: $color-white;
}
