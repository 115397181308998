@import "variable";

// 基本設定
html {
  background-color: $color-light-blue;
}

body {
  color: $color-black;
  background-color: $color-light-blue !important;

  font-family: Noto Sans,sans-serif !important;
  & * {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }
}

dl {
  list-style-type: none;
}

img {
  vertical-align: bottom;
  border-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.text-color-black {
  color: $color-black;
}

.text-center {
  text-align: center;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type="date"] {
  background: white;
}

input[type="checkbox"] {
  display: none;
}

hr {
  height: 1px;
  background-color: $color-gray;
  border: none;
}

#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 40px;
  background: $color-white;
  border-radius: 16px;
}

.content-wrapper {
  max-width: 1536px;
  margin: 0 auto;
}

/*common定義*/
#wrapper {
  background-color: rgba($color-light-blue, 0.05);
  position: relative;
  padding: 0 40px 50px 40px;
  text-align: center;
}

#wrapper-gray {
  background-color: $color-gray2;
  position: relative;
  text-align: center;
  padding-bottom: 100px;
}

.inner {
  margin: 80px auto 40px;
  width: 100%;
  max-width: 1360px;
  position: relative;
  background: $color-white;
  padding: 80px 50px;
  border-radius: 16px;
  text-align: left;
}

.inner-block {
  padding: 0 40px;
  max-width: 1440px;
  margin: auto;
}

.inner-login {
  width: 100%;
  max-width: 490px;
  background: $color-white;
  padding: 80px 50px;
  border-radius: 16px;
  text-align: center;
  margin: 80px auto 40px;
}

.inner-company,
.inner-results {
  margin: 0 auto 40px;
  width: 100%;
  max-width: 1456px;
  position: relative;
  background: $color-bg-blue;
  padding: 40px;
  border-radius: 0 16px 16px 16px;
  text-align: left;
}
.panel-detail-appplicant {
  margin: 0 auto 40px;
  width: 100%;
  position: relative;
  background: $color-bg-blue;
  padding: 40px;
  border-radius: 0 16px 16px 16px;
  text-align: left;
}
.section-ttl {
  margin-bottom: 20px;
  color: $color-blue;
  font-size: 20px;
  font-weight: bold;
}

#footer {
  color: $color-font1-gray;
  font-size: 16px;

  span {
    font-size: 12px;
  }
}

// a {
//   color: $color-blue;
// }

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t--30 {
  margin-top: -30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t--50 {
  margin-top: -50px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-27 {
  margin-bottom: 27px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-136 {
  margin-left: 136px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.w-full {
  min-width: 100% !important;
}
textarea {
  resize: none;
}
h1 {
  font-size: 20px;
  font-weight: bold;
  color: $color-blue;
  margin-bottom: 30px;

  span {
    font-size: 16px;
  }
}

.float-left {
  float: left;
}

.txt-center {
  text-align: center;
  margin: auto;
}

.checkbox-item {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 25%;
  }
}

.items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.display-flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.position-relative {
  position: relative;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  border-radius: 16px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 6px !important;
  max-width: 500px !important;
}

.icon-forgot-password {
  font-size: 24px !important;
  margin-bottom: 2px !important;
}

.no-border {
  border: none !important;
}
button:focus,
select:focus,
input:focus {
  outline: 0;
}
.toastParent {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.toast {
  position: fixed;
  top: 8%;
  left: 40%;
  color: #43766c;
  background-color: #cff7ec;
  border-radius: 30px;
  padding: 10px 30px;
  opacity: 0.9;
  z-index: 50000000;
  animation: toast ease-in 0.7s;
}
.toastFail {
  position: fixed;
  top: 8%;
  left: 40%;
  color: $color-red;
  background-color: rgb(255, 220, 220);
  border-radius: 30px;
  padding: 10px 30px;
  opacity: 0.9;
  z-index: 50000000;
  animation: toast ease-in 0.7s;
}

.h-20 {
  height: 20px;
}

.grow-1 {
  flex-grow: 1;
}

.grow-400 {
  flex-grow: 400;
}

.grow-200 {
  flex-grow: 200;
}

.grow-300 {
  flex-grow: 300;
}
.m-w-1360 {
  max-width: 1360px !important;
}

.relative {
  position: relative;
}

.color-focus {
  color: #fcdf80 !important;
}

.common-text-blue {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.04em;

  color: #00c4cc;
}

.common-text-blue-normal {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.04em;

  color: #00c4cc;
}

.common-text-black {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  letter-spacing: 0.04em;

  color: #555555;
}

.common-text-gray {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  letter-spacing: 0.04em;

  color: #aaaaaa;
}

.blank-block {
  height: 20px;
  width: 100%;
  opacity: 0;
  // background-color: #fff;
  // display: none;
}

@keyframes toast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
