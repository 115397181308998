@import "variable";

// 基本設定
header {
  display: flex;
  padding: 22px 0;
  align-items: center;
  justify-content: space-between;

  nav {
    display: flex;
    margin-left: 54px;
    align-items: center;
    a {
      padding: 0 20px;
      color: $color-white;
    }
    .nav-toggle {
      padding: 0 32px 0 20px;
      &.bottom-triangle:after {
        color: $color-white;
      }
      .menu {
        top: auto;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
    }

    .color-focus {
      color: #ffeaaf;
    }
    .current {
      color: $color-yellow;
    }
  }
  .header-logo {
    width: 190px;
  }
  .header-search {
    position: relative;
    margin: 0 20px 0 0;
    input[type="text"] {
      margin-bottom: 0;
      border-radius: 20px;
    }
    .search-icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }
  .header-account {
    position: relative;
  }
  .header-user {
    padding-right: 32px;
    &.bottom-triangle:after {
      color: $color-white;
    }
  }

  #tg {
    display: none;
  }
  .menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    bottom: auto;
    right: 0;
    margin-top: 8px;
    list-style: none;
    box-shadow: 0px 0px 5px $color-gray;
    a,
    button {
      padding: 12px;
      display: block;
      width: 100%;
      min-width: max-content;
      background-color: $color-white;
      text-align: center;
      position: relative;
      color: #333;
      text-decoration: none;
      font-size: 14px;
      &:hover {
        background-color: $color-blue;
        color: #fff;
        opacity: 1;
      }
    }
  }
  .dropInput:checked ~ .menu {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  .dropInput:checked ~ .color-check {
    color: #ffeaaf;
  }
  .dropdown-bt:hover {
    cursor: pointer;
  }
  .dropdown-bt::after {
    content: "";
    position: absolute;
    right: -30px;
    min-height: 22px;
    min-width: 30px;
    z-index: 1;
  }
}

.breadcrumb {
  padding: 0;
  a {
    position: relative;
    margin: 20px 0 40px;
    padding-left: 12px;
    color: $color-black;
    text-align: left;
    span {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      right: 8px;
      width: 6px;
      height: 6px;
      transform: translateY(-50%) rotate(45deg);
      -webkit-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
      content: "";
      border-left: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
    }
  }
}
