@import "variable";

.help-container {
  max-width: 1360px;
  margin: 0px auto;
  display: block;
  padding: 0px 40px;
  box-sizing: content-box;
  .help-inner-content {
    display: block;
    flex-direction: column;
    align-items: center;
    

    .search-bar-container {
  
      height: 134px;
      background: #ffffff;
      border-radius: 8px;

      .search-bar-content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 900px;
        height: 54px;
        margin: 0px 40px;

        .search-bar {
          background: rgba(250, 190, 0, 0.1);
          border-radius: 27px;
          height: 54px;
          border: none;
          margin-bottom: 0;

          // text
          padding-left: 30px;
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          /* identical to box height, or 150% */
          letter-spacing: 0.04em;
          color: #aaaaaa;
        }
      }

      .search-icon {
        position: absolute;
        right: 2%;
        top: 34%;
      }
    }

    .help-content {
      margin: 50px auto 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 900px;
      .help-list {
        margin-bottom: 50px;
        width: 100%;

        .list-title {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: 0.04em;
          color: #555555;
          margin-bottom: 20px;
        }

        .help-item {
          // width: 900px;
          
          background: #ffffff;
          border-radius: 4px;
          margin-bottom: 10px;
          cursor: pointer;
          .help-item-content {
            border-top: 1px solid #cccccc;
            margin: 0px 30px;
          }
          dl {
            dt {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .item-title {
                font-family: "Noto Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 60px;
                /* identical to box height, or 150% */
                letter-spacing: 0.04em;
                color: #555555;
                margin-left: 30px;
              }

              .item-icon {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
  }
}
