@import "variable";

/*botton*/
.btn-blue-big {
  background: $color-blue;
  color: $color-white;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  padding: 14px 0px;
  border: none;
  height: 55px;
  cursor: pointer;
}

.btn-red-big {
  background: $color-red;
  color: $color-white;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  padding: 14px 60px;
  border: none;
  cursor: pointer;
}

.btn-blue-medium {
  background: $color-blue;
  color: $color-white;
  border-radius: 28px;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 60px;
  border: none;
  cursor: pointer;
}
.btn-blue-small {
  background: rgba($color-blue, 0.05);
  color: $color-blue;
  border-radius: 4px;
  font-size: 14px;
  padding: 3px 8px;
  cursor: pointer;
  border: 1px solid $color-blue;
  max-width: 118px;
}
.btn-border-blue {
  background: $color-white;
  color: $color-blue;
  border: 1px solid $color-blue;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.btn-square-blue {
  background: $color-white;
  color: $color-blue;
  border: 1px solid $color-blue;
  padding: 8px 20px;
  cursor: pointer;
}
.btn-border-blue-padding-plus {
  background: $color-white;
  color: $color-blue;
  border: 1px solid $color-blue;
  padding: 8px 40px;
  border-radius: 20px;
  cursor: pointer;
}
.btn-border-blue-blod-big {
  background: $color-white;
  color: $color-blue;
  border: 2px solid $color-blue;
  font-weight: bold;
  padding: 14px 60px;
  border-radius: 30px;
  cursor: pointer;
}

.btn-border-blue-blod-medium {
  background: $color-white;
  color: $color-blue;
  border: 2px solid $color-blue;
  font-weight: bold;
  padding: 8px 40px;
  border-radius: 28px;
  cursor: pointer;
}

.btn-square-red {
  background: $color-red;
  color: $color-white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 34px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
}

.btn-small-square-blue {
  background: $color-blue;
  color: $color-white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 34px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
}

.btn-square-gray {
  background: $color-dark-gray;
  color: $color-white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 10px !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.btn-square-gray:hover {
  opacity: 0.7;
}

.btn-flex-item {
  text-align: center;
  margin: auto;
  input {
    margin: 0 10px;
  }
}

/*input*/

// input[type="email"],
// input[type="password"],
// input[type="number"],
// textarea,
// input[type="date"] {
//   background: $color-light-yellow;
//   border-radius: 4px;
//   font-size: 16px;
//   border: none;
//   color: #555555;
//   border: 1px solid #ccc;
//   width: 100%;
//   padding: 0 10px;
//   font-weight: 500;
// }

textarea {
  max-width: 100% !important;
  // min-width: 100% !important;
}
input[type="date"] {
  max-width: 340px;
}

// input[type="number"],
// input[type="password"],
// input[type="email"] {
//   height: 40px;
//   padding: 0 10px;
// }

.input-w-200 {
  max-width: 200px;
  margin-right: 5px;
}
.select {
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  color: $color-black;
  outline: none;
  border: 1px solid #ccc;
  cursor: pointer;
  appearance: none;
  padding-right: 14px;
}
select::after {
  background: url("https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png")
    no-repeat scroll right center transparent;
}
.select-item {
  position: relative;
  .select {
    width: 100%;
  }
}
.select-item::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent $color-black transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  transform: rotate(180deg);
  pointer-events: none;
}
.select-item-w340 {
  width: 100%;
  max-width: 340px;
}
.select-item-w150 {
  width: 100%;
  max-width: 150px;
}
.checkbox-search {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
}
.checkbox-search::before {
  background: $color-white;
  border: 1px solid $color-gray;
  border-radius: 3px;
  background: $color-light-yellow;
  content: "";
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.checkbox-search::after {
  border-right: 6px solid $color-blue;
  border-bottom: 3px solid $color-blue;
  content: "";
  display: block;
  height: 20px;
  left: 7px;
  margin-top: -16px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 9px;
}
input[type="checkbox"]:checked + .checkbox-search::before {
  border-color: $color-font1-gray;
}
input[type="checkbox"]:checked + .checkbox-search::after {
  opacity: 1;
}
.tag-red {
  background: $color-red;
  color: $color-white;
  border-radius: 2px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px !important;
  margin-left: 8px;
}
.tag-gray {
  background: $color-font1-gray;
  color: $color-white;
  border-radius: 2px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px !important;
  margin-left: 8px;
}
.not_required_admin {
  background: #FAFAFA;
  color: #595959;
  border-radius: 2px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px !important;
 
}
.not_required {
  background: $color-font1-gray;
  color: $color-white;
  border-radius: 2px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px !important;
 
}
.tag-edit,
.tag-duplicate,
.tag-preview {
  background: $color-blue;
  color: $color-white;
  border-radius: 4px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px !important;
  margin-left: 8px;
  a {
    color: $color-white;
  }
}
.tag-del {
  color: $color-blue;
  border-radius: 2px;
  font-size: 13px;
  max-height: 22px;
  padding: 1px 5px;
  margin-left: 23px;
}
.icon-qa {
  margin-left: 8px;
  img {
    max-width: 14px;
    margin-bottom: 4px;
  }
}
.icon-qa-big {
  margin-left: 8px;
  img {
    max-width: 30px;
    margin-bottom: 4px;
  }
}
.btn-more {
  color: $color-blue;
  font-weight: bold;
  border: 2px solid $color-blue;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
}

.btn-more-add {
  color: $color-blue;
  border: 1px solid $color-blue;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  img {
    max-width: 10px;
    margin-right: 5px;
  }
}
.btn-more-open {
  color: $color-blue;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid $color-blue;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}
.form-btn-area {
  margin: 40px auto 0;
  text-align: center;
  &.sticky {
    bottom: 20px;
  }
}
