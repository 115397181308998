.list-applicant-table {
  .table-cell-head {
    padding: 40px 12px;
  }
  
}
.applicant-button {
  color: #fff !important;
  border-radius: 4px !important;
  padding: 4px 15px !important;
  display: block !important;
  width: 100% !important;
  cursor: pointer !important;
  text-align: center !important;
}
.applicant-btn-chat {
  background: #00c4cc !important;
}
.applicant-btn-info {
  background: #ffcc17;
}