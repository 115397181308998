@import "variable";
@import "list";

// 効果レポート出力
.csv-upload {
  border-radius: 16px;
  .inner {
    padding: 40px;
    margin: 0;
    margin-bottom: 20px;
    max-width: 100%;
    .csv-item-desc {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      letter-spacing: 0.04em;

      color: #555555;
    }

    .upload-item-container {
      align-items: center;
      display: flex;
      div {
        width: 100%;
      }

      .btn-border-blue {
        background: $color-white;
        color: $color-blue;
        border: 1px solid $color-blue;
        padding: 8px 20px;
        cursor: pointer;
        //inner text
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: 0.04em;
      }

      .upload-item-content {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        margin-left: 10px;
        letter-spacing: 0.04em;

        color: #555555;
      }

      .upload-item-desc {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        margin-top: 4px;
        letter-spacing: 0.04em;

        color: #aaaaaa;
      }
    }

    .btn-blue-medium {
      bottom: 39px;
      left: calc(50% - 100px);
      width: 200px;
      height: 56px;
      border-radius: 28px;
    }
  }
}

.csv-download {
  border-radius: 16px;
  .inner {
    padding: 40px;
    margin: 0px auto 20px;
    max-width: 100%;
    .inner-header {
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 20px;
    }

    .inner-content {
      display: grid;
      grid-template-columns: auto auto 33%;
      gap: 10px;
      input {
        width: 100%;
      }

      .select-wrapper {
        width: 100%;
        margin: 0;
        .select-items {
          width: 100%;
          height: 43px;
          padding: 8px 10px;
          border: solid 1px #ccc;
          border-radius: 4px;
          &:before {
            display: none;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4.5px solid $color-black;
          }
        }
      }
    }

    .items-center {
      .btn-blue-medium {
        height: 56px;
      }
    }
  }
}
.result-table {
  width: 100%;
  .search-message {
    background: rgba(0, 196, 204, 0.2);
    border-radius: 4px !important;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.04em;
  }
  .search-message th {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.04em;

    color: #555555;
  }
}

.selection-count-text {
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.04em;

  color: #555555;
}

.csv-table-result-td {
  line-height: 36px;
}

.csv-table-result-td.job-name {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 150% */

  letter-spacing: 0.04em;

  color: #555555;
}

.csv-table-result-td.status-result {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 150% */

  letter-spacing: 0.04em;

  color: #555555;
}

.csv-table-result-td.internal-keywords {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* identical to box height, or 150% */
  letter-spacing: 0.04em;
  color: #555555;
}

.csv-upload-image {
  border-radius: 16px;
  .inner {
    padding: 40px;
    margin: 0px auto 20px;
    max-width: 100%;
    .header-container {
      padding-bottom: 30px;
      border-bottom: 1px solid #ccc;

      .desc {
        font-style: normal;
        font-weight: 550;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */
        letter-spacing: 0.04em;
        color: #555555;
      }
    }

    #upload-image-form {
      //image form
      .upload-image-container {
        ul {
          li {
            margin-bottom: 10px;

            dl {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              // dd:last-child {
              //   margin-left: 10px;
              // }
              > :first-child {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                /* identical to box height, or 131% */
                letter-spacing: 0.04em;
                color: #555555;
                margin-right: 15px;
              }

              :nth-child(2) {
                .btn-border-blue {
                  background: $color-white;
                  border: 1px solid $color-blue;
                  border-radius: 4px;
                  padding: 8px 20px;
                  cursor: pointer;
                  //inner text
                  color: $color-blue;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height, or 150% */
                  letter-spacing: 0.04em;
                  margin-right: 0px;
                }
              }

              :last-child {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height, or 150% */
                letter-spacing: 0.04em;
                color: #555555;
              }
            }
          }
        }

        .upload-note {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          /* or 150% */
          letter-spacing: 0.04em;
          color: #aaaaaa;
        }
      }

      //Search Table
    }
  }
}
.search-table {
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 30% auto;
  gap: 30px;
  padding: 40px;
  .search-block {
    .inner-content {
      display: grid;
      grid-template-columns: auto;
      gap: 10px;

      .input-txt {
        width: 100%;
        padding: 0 10px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 43px;
      }

      .select-wrapper {
        width: 100%;
        margin: 0;
        .select-items {
          height: 43px;
          margin-bottom: 20px;
          padding: 0 10px;
          border: solid 1px #ccc;
          border-radius: 4px;
          width: 100%;
          &:before {
            display: none;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4.5px solid $color-black;
          }
        }
      }
      .btn-container {
        .btn-border-blue-blod-medium {
          background: $color-white;
          border: 2px solid $color-blue;
          padding: 8px 40px;
          border-radius: 20px;
          cursor: pointer;

          // inner text
          color: $color-blue;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: 0.04em;
        }
      }
    }
  }

  .search-result {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    overflow: auto;
  }
}
.csv-cropper-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .csv-cropper {
    height: 75%;
    position: relative;
    .cropper-close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      min-width: 56px;
      min-height: 56px;
      background-color: #fff;
      border-radius: 100%;

      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .csv-slider {
    width: 40%;
    margin-top: 40px;
    align-self: center;
  }
}

.search-result-item {
  border-bottom: 1px solid #dee2e6;
}

.delete-img-btn {
  position: absolute;
  width: 28px;
  cursor: pointer;
  top: -14px;
  right: -14px;
}

.delete-storage-img-btn{
  position: absolute;
  width: 28px;
  cursor: pointer;
  top: -14px;
  right: -14px;
}

.status-1 {
  width: 60px;
  background-color: #9ce4b6;
  color: #1b6736;
  text-align: center;
  border-radius: 4px;
}

.status-2 {
  width: 60px;
  background-color: #ffe99a;
  color: #806a1e;
  text-align: center;
  border-radius: 4px;
}

.status-3 {
  width: 60px;
  background-color: #cacaca;
  color: #525151;
  text-align: center;
  border-radius: 4px;
}

.csv-update-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 200px;
  }
  .csv-footer-text {
    margin-top: 20px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    letter-spacing: 0.04em;

    color: #555555;
  }
}
