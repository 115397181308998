@import 'variable';

// 効果レポート出力
.anaysis-item{
    .inner{
        padding: 40px !important;
    }
}
.calander-item{
    display: flex;
    align-items: center;
    .btn-blue-medium{
        padding: 8px 40px !important;
        margin-left: 20px;
    }
    input[type="date"]{
    margin-bottom: 0 !important;
    }
}