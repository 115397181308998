@import "variable";

#preview-header {
  box-sizing: content-box;
  padding: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(0 196 204 / var(--tw-bg-opacity));
  height: 60px;
  .header-container {
    padding: 0 1.25rem;
    margin: 0 auto;
    width: 960px;
    max-width: 960px;
    height: 60px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 60px;
      .company-name {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        font-weight: 700;
        .company-name-text1 {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */

          letter-spacing: 0.04em;
        }
        .company-name-text2 {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height, or 125% */

          letter-spacing: 0.1em;
        }
      }
      .company-job-info {
        display: flex;
        flex-direction: column;
        justify-items: center;
        .job-info-logo {
          display: flex;
          justify-content: center;
        }
        .job-info-description {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          /* identical to box height, or 150% */

          letter-spacing: 0.04em;

          color: #fcdf80;
        }
      }
    }
  }
}

.inner-container {
  max-width: 960px;
  margin: auto;
  position: relative;
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // max-width: 960px;
  margin: 0 auto;
  .company-banner {
    width: 100%;
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
  .company-menu-content {
    width: 100%;
    background: rgba(0, 196, 204, 0.15);
    .company-first-slogan {
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height, or 150% */
      text-align: center;
      letter-spacing: 0.2em;
      color: #555555;
    }
    .company-menu-box {
      background: #ffffff;
      border-radius: 8px;
      margin: 20px 40px 30px;
      padding-bottom: 30px;
      .menu-box-title {
        padding-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height, or 150% */
        text-align: center;
        letter-spacing: 0.4em;
        color: #555555;
      }
      .menu-box-item {
        margin: 20px 40px 0;
        border-bottom: 1px solid #00c4cc;

        .menu-box-item-content {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */
          letter-spacing: 0.2em;
          color: #555555;
          text-decoration: none;
        }
      }
    }
  }

  .basic-design-container {
    background-color: #ffffff;
    width: 100%;
    padding-bottom: 10px;
    .basic-design-title {
      margin-top: 80px;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height, or 150% */
      text-align: center;
      letter-spacing: 0.2em;
      color: #00c4cc;
    }
    .basic-design-text {
      padding: 0px 20px;
      margin-top: 10px;
      margin-bottom: 20px;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      /* or 150% */
      letter-spacing: 0.04em;
      color: #555555;
    }
    .basic-design-images-wrapper {
      // display: flex;
      // justify-content: space-evenly;
      .basic-design-image {
        // width: 30%;
        // border: 1px solid #dddddd;
        // border-radius: 8px;
        width: 92%;
        border: 1px solid #dddddd;
        border-radius: 8px;
      }
    }
  }
  .image-design-container {
    background-color: #ffffff;
    width: 100%;
    padding-bottom: 10px;
    .image-design-title {
      margin-top: 80px;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height, or 150% */
      text-align: center;
      letter-spacing: 0.2em;
      color: #00c4cc;
    }
    .image-design-images-wrapper {
     
      .image-design-img {
        width: 92%;
        border: 1px solid #dddddd;
        border-radius: 8px;
      }
      .image-description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        letter-spacing: 0.04em;
        color: #555555;
      }
    }
  }

  // Job List
  .preview-job-list {
    width: 100%;
    background: #00c4cc;
    .preview-job-list-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height, or 150% */
      text-align: center;
      letter-spacing: 0.2em;
      color: #ffffff;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .preview-job-list-status {
      .list-status-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      li {
        margin-bottom: 10px;
        margin-right: 20px;
        dl {
          align-items: center;

          .status-title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height, or 150% */
            letter-spacing: 0.04em;
            color: #ffffff;
            min-width: 60px;
            margin: 0px 20px;
          }
          .status-select-wrapper {
            position: relative;
          }
          .status-select-wrapper::after {
            content: "";
            position: absolute;
            width: 0;
            border-top: 4.5px solid #555;
            border-left: 4px solid #0000;
            border-right: 4px solid #0000;
            top: 21px;
            right: 9px;
          }
          .status-select {
            width: 100%;
            height: 40px;
            background: #fff8e5;
            border: none;
            padding: 0 10px;
            border-radius: 4px;
          }
        }
      }
    }
    .preview-job-list-content {
      li {
        background-color: #fff;
        border-radius: 8px;
        margin: 20px;
        .job-img-wrapper {
          margin: 20px 10px 20px 20px;
          .job-img {
            max-width: 90px;
          }
        }
        dd {
          margin-top: 20px;
          margin-bottom: 20px;
          .job-title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            /* or 150% */
            letter-spacing: 0.04em;
            color: #00c4cc;
          }
          .job-tag,
          .job-info-data {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height, or 150% */
            letter-spacing: 0.04em;
            color: #555555;
          }
          .job-info {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            width: 60px;
            /* identical to box height, or 150% */
            letter-spacing: 0.04em;
            color: #555555;
          }
        }
      }
    }
  }
}

//Selection Flow
.selection-flow-container {
  background-color: #ffffff;
  width: 100%;
  .selection-flow-title {
    margin: 80px 0px 20px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.2em;

    color: #00c4cc;
  }
  .selection-flow-content {
    .selection-flow-list {
      .selection-flow-item {
        margin-bottom: 20px;
        dt {
          min-width: 32px;
          height: 32px;
          background: #00c4cc;
          border-radius: 4px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          /* or 150% */
          text-align: center;
          color: #ffffff;
          margin: 0px 10px 0px 20px;
        }
        .item-title {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: 0.04em;
          color: #555555;
        }
        .item-description {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          /* or 150% */
          letter-spacing: 0.04em;
          color: #555555;
          margin-right: 20px;
        }
        .has-line {
          display: flex;
          justify-content: center;
          position: relative;
          margin-top: 20px;
        }
        .has-line::before,
        .has-line::after {
          content: "";
          position: absolute;
          width: 45%;
          height: 2px;
          background-color: rgba(0, 196, 204, 0.5);
          opacity: 0.5;
          top: 2px;
        }
        .has-line::before {
          left: 20px;
        }
        .has-line::after {
          right: 20px;
        }
      }
    }
  }
}

// Company Info

.company-info-container {
  background: #777777;
  width: 100%;
  padding-bottom: 30px;
  .company-logo-wrapper {
    text-align: center;
    margin: 40px 0px;
    .company-logo {
      height: 80px;
    }
  }
  .company-info-list {
    .company-info-item {
      margin-bottom: 10px;
      .company-info-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        letter-spacing: 0.04em;
        min-width: 70px;
        color: #ffffff;
        margin: 0px 20px;
        padding-left: 8px;
        max-height: 21px;
        border-left: 2px solid #00c4cc;
      }
      .company-info-detail {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        letter-spacing: 0.04em;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}

// Copyright

.copyright-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 85px;
  .copyright-content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: 0.04em;
    color: #555555;
  }
}

// React Elastic Carousel

//rec-arrow

.rec.rec-arrow {
  // position: absolute;
  background-color: #fff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  min-width: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
}

.rec.rec-arrow-left {
  position: absolute;
  left: 6px;
  z-index: 1;
}

.rec.rec-arrow-right {
  position: absolute;
  right: 6px;
  z-index: 1;
}

//rec-arrow focus
.rec.rec-arrow:hover:enabled,
.rec.rec-arrow:focus:enabled {
  background-color: #00c4cc !important;
  border: 1px solid #00c4cc;
}

//rec-item-wrapper

.rec.rec-item-wrapper .image-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//rec-dot
.rec.rec-dot {
  background-color: #cccccc;
  box-shadow: none !important;
}

.rec-dot_active {
  box-shadow: none !important;
  background-color: #555555 !important;
}
