@import "variable";

#job-preview-header {
  box-sizing: content-box;
  padding: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(0 196 204 / var(--tw-bg-opacity));
  height: 70px;
  .header-container {
    padding: 0 20px;
    margin: 0 auto;
    width: 1000px;
    max-width: 1000px;
    height: 60px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 60px;
      .company-name {
        width: 180px;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        font-weight: 700;
        .company-name-text1 {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 150% */

          letter-spacing: 0.04em;
        }
        .company-name-text2 {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height, or 125% */

          letter-spacing: 0.1em;
        }
      }
    }
  }
}

.job-banner {
  width: 100%;
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
}

.job-tag-name-container {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 15px;
  .job-name {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: #00c4cc;
  }
  .job-tag-container {
    display: flex;
    flex-wrap: wrap;

    .job-tag {
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 4px 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #555555;
      margin-bottom: 5px;
    }
  }
}

.contact-container {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: linear-gradient(0deg, #777777, #777777), #ffffff;
  .contact-inner-content {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    width: 100%;
    padding: 0 20px;
    margin: 0px auto;
    .phone-contact {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, #fabe00, #fabe00), #ffffff;
      border-radius: 4px;
      height: 70px;
      width: 49%;
      min-width: 164px;
      margin: 20px 0px;
      cursor: pointer;
      .phone-contact-icon {
        color: #ffffff;
        margin-right: 4px;
      }
      .phone-contact-text {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #ffffff;
      }
    }
    .web-contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 164px;
      width: 49%;
      background: linear-gradient(0deg, #00c4cc, #00c4cc), #ffffff;
      border-radius: 4px;
      height: 70px;
      margin: 20px 0px;
      cursor: pointer;
      .web-contact-title {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #ffffff;
      }
      .web-contact-description {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #ffffff;
      }
    }
  }
}

.job-slogan-container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 40px;
  .job-slogan-copy {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */
    letter-spacing: 0.04em;
    color: #555555;
    white-space: pre-line;
  }
  .job-slogan-read {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.04em;
    color: #555555;
    white-space: pre-line;
  }
}

.preview-body-container {
  padding: 40px 0;
  background: rgba(0, 196, 204, 0.1);
  //   Job Description
  .job-description-container {
    padding: 20px 0;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .job-description {
      margin: 16px 20px 0px;
      white-space: pre-line;
    }
  }
  .job-condition-container {
    padding: 20px 0;
    background-color: #fff;
    // margin-bottom: 20px;
    border-radius: 8px;
    .job-condition-content {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.04em;
      color: #555555;
      margin: 16px 20px 0;
      white-space: pre-line;
    }
  }

  .job-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .job-preview-image {
      // max-height: 180px;
      width: 96%;
      border-radius: 8px;
    }
    .job-image-description {
      margin-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #555555;
      padding: 0px 10px
    }
  }

  .job-details-container {
    padding: 20px 0;
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0px;
    .job-details-content {
      margin-top: 16px;
      .job-detail {
        margin: 10px 20px 0px;
        .job-detail-title {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: #555555;
          border-left: 2px solid #00c4cc;
          padding-left: 8px;
          margin-bottom: 4px;
        }
        .job-detail-description {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: #555555;
          padding-left: 8px;
          white-space: pre-line;
        }
      }
    }
  }

  .job-otherinfo-container {
    padding: 20px 0;
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0px;
    .job-otherinfo-content {
      margin: 16px 20px 0px;
      .otherinfo-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.04em;
        color: #555555;
        margin-bottom: 4px;
      }
      .otherinfo-content {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.04em;
        color: #555555;
        white-space: pre-line;
      }
    }
  }

  .job-location-container {
    padding: 20px 0;
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0px;
    .job-location-content {
      margin: 16px 20px 0px;
      .job-location {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.04em;
        color: #555555;
        margin-bottom: 10px;
      }
      .job-location-map {
        height: 300px;
      }
    }
  }
}

// Job List Slider

.job-slider-container {
  background-color: #fff;
  padding: 40px 0;
  .job-list-slider-container {
    .job-slider-item {
      border: 1px solid #eeeeee;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      max-width: 180px;
      min-height: 235px;
      .job-item-inner-content {
        padding: 20px;
        align-items: flex-start !important;
        .job-item-image {
          height: 100px;
          width: 140px;
        }
        .job-item-name {
          margin-top: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: #00c4cc;
        }
        .job-item-workplace {
          display: flex;
          font-style: normal;
          font-weight: 900;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.04em;
          color: #aaaaaa;
        }
      }
    }
  }
}

// Applicant Form

.applicant-form-container {
  background-color: #00c4cc;
  padding: 40px 0;
  .applicant-form-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .applicant-inner-content {
    max-width: 960px;
    padding: 30px 20px 20px;
    margin: 0px auto;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    .applicant-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      letter-spacing: 0.04em;
      color: #00c4cc;
      margin-top: 10px;
    }
    .applicant-form-list {
      .applicant-form-item {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eeeeee;
        .item-title {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.04em;
          color: #555555;
          margin-bottom: 5px;
        }
        .applicant-checkbox {
          .applicant-checkbox-custom {
            // display: block;
            position: relative;
            padding-left: 16px;
            margin-right: 40px;
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height, or 150% */
            letter-spacing: 0.04em;
            color: #555555;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .checkmark-custom {
              position: absolute;
              top: 2px;
              left: 0;
              height: 14px;
              width: 14px;
              background-color: #fff;
              border: 1px solid #aaaaaa;
              border-radius: 50%;
            }
            .checkmark-custom::after {
              content: "";
              position: absolute;
              display: none;

              top: 4px;
              left: 4px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: white;
            }
          }
          .applicant-checkbox-custom:hover input ~ .checkmark-custom {
            background-color: #ccc;
          }
          .applicant-checkbox-custom input:checked ~ .checkmark-custom {
            background-color: #2196f3;
          }
          .applicant-checkbox-custom input:checked ~ .checkmark-custom::after {
            display: block;
          }

          .applicant-checkbox-custom input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.copy-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
}

// Common

.job-img-inner-content {
  max-width: 1000px;
  padding: 0 6px;
  margin: 0px auto;
  position: relative;

}

.job-inner-content {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0px auto;
  position: relative;
}

.job-preview-logo {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: 0.04em;
  color: #00c4cc;
  margin-bottom: 8px;
}

.job-preview-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: 0.2em;
  color: #00c4cc;
}
