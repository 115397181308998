@import "variable";

// 企業情報詳細
.results-item {
  width: 100%;
  max-width: 1280px;
  position: relative;
  background: $color-white;
  padding: 40px;
  border-radius: 16px;
  text-align: left;
  margin-top: 20px;
  textarea {
    background: $color-white;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    border: 1px solid $color-gray;
    margin-bottom: 10px;
    width: 100%;
  }
  textarea:focus {
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    color: $color-black;
    outline: none;
    border: 1px solid $color-yellow;
  }
  .colse2 {
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
  }
  th:nth-child(n + 1),
  th:nth-child(n + 2) {
    width: 17% !important;
  }
  th:nth-child(n + 3) {
    width: 56% !important;
  }
  th:nth-child(n + 4) {
    width: 10% !important;
    text-align: center;
  }
  td {
    textarea {
      height: 120px;
      margin-bottom: 0;
    }
    .txt-link-gray {
      margin-top: 0 !important;
    }
  }
  td:nth-child(n + 4) {
    text-align: center;
  }
}
.results-item:first-child {
  margin-top: 0;
}
.results-h1-item {
  width: 100%;
  max-width: 1360px;
  margin: 40px auto 0 auto;
  display: flex;
}

.tab-detail-applicant {
  width: 100%;
  margin: 40px auto 0 auto;
  display: flex;
}
.results-h1-on {
  color: $color-blue;
  font-weight: bold;
  font-size: 18px;
  background: $color-bg-blue;
  padding: 20px;
  margin: 0 10px 0 0;
  border-radius: 8px 8px 0 0;
  width: fit-content;
}
.results-h1-off {
  color: $color-black;
  font-size: 18px;
  background: $color-white;
  padding: 20px;
  margin: 0 10px 0 0;
  border-radius: 8px 8px 0 0;
  width: fit-content;
  a {
    color: $color-black;
  }
}
.results-ttl-item {
  display: flex;
  // margin-bottom: 20px;
  border-bottom: 1px solid $color-gray;
  padding: 20px 0;
  .results-ttl {
    font-size: 16px;
    font-weight: bold;
    width: calc(100% - 260px);
  }
  .results-img {
    width: calc(100% - 260px);
  }
  .results-btn {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    max-width: 260px;
    a {
      margin-left: 10px;
      img {
        width: 25px;
      }
    }
  }
}
.results-flex-item {
  display: flex;
  ul {
    width: 45%;
  }
  .results-flex-btn {
    margin-left: 40px;
  }
}
.results-list {
  li {
    padding: 10px 0;
    dl {
      display: flex;
      font-size: 14px;
      dt {
        width: 20%;
        display: flex;
        min-width: 230px;
      }
      dd {
        width: 80%;
        .flex-box {
          display: flex;
          .flex-box-ttl {
            width: 20%;
            min-width: 100px;
          }
          .flex-box-txt {
            width: 80%;
          }
        }
        .flex-box:first-child {
          margin-bottom: 10px;
        }
        .btn-blue-small {
          margin-right: 10px;
        }
        p {
          display: flex;
          align-items: center;
          span {
            padding: 8px 10px 8px 0;
          }
        }
        input,
        textarea {
          margin-bottom: 0;
        }
        select {
          width: 100%;
        }
        textarea {
          height: 120px;
        }
        .txt-link-blue {
          color: $color-blue;
          margin-bottom: 10px;
        }
        .txt-link-gray {
          color: $color-gray;
          margin-top: 5px;
        }
        .img-up-item {
          max-width: 200px;
          position: relative;
          margin-top: 10px;
          .img {
            img {
              width: 100%;
              object-fit: cover;
              max-width: 200px;
              height: auto;
            }
          }
          .colse {
            z-index: 6666;
            position: absolute;
            width: 40px;
            height: 40px;
            right: -20px;
            top: -20px;
          }
        }
      }
    }
  }
}
.table-item {
  width: 100%;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  tr {
    th {
      padding: 30px 10px;
      font-weight: bold;
      background: #f7fdfe;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
    }
    th:first-child,
    th:nth-child(n + 1) {
      width: 33%;
    }
    th:nth-child(n + 3),
    th:nth-child(n + 4) {
      width: 17%;
    }
    td {
      padding: 30px 10px;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
      .line-right {
        border-right: 1px solid $color-gray;
        padding-right: 20px;
        margin-right: 10px;
      }
      a {
        display: inherit;
      }
    }
  }
}
.right-btn-item {
  width: 100%;
  max-width: 1280px;
  text-align: right;
  margin: 40px 0 20px auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  input {
    margin-left: 10px;
  }
}

.toggle-button-cover {
  position: relative;
  width: 260px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 260px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
  input[type="checkbox"] {
    display: block !important;
  }
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: rgba($color-blue, 0.1);
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 18 */
#button-18 .knobs:before,
#button-18 .knobs span {
  content: "ユーザー画面で表示中";
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: $color-blue;
  border-radius: 2px;
}

#button-18 .knobs:before {
  top: 50%;
  left: 1px;
  width: 180px;
  height: 28px;
  margin-top: -5px;
  background-color: transparent;
  z-index: 2;
}

#button-18 .knobs span {
  width: 170px;
  height: 28px;
  padding: 9px 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  z-index: 1;
}

#button-18 .checkbox:active + .knobs:before {
  left: 10px;
  width: 60px;
  height: 4px;
  color: transparent;
  margin-top: -2px;
  background-color: $color-blue;
  transition: 0.3s ease all;
  overflow: hidden;
}

#button-18 .checkbox:active + .knobs span {
  width: 260px;
}

#button-18 .checkbox:checked:active + .knobs:before {
  left: auto;
  right: 10px;
  background-color: $color-red;
}

#button-18 .checkbox:checked:active + .knobs span {
  margin-left: 0px;
}

#button-18 .checkbox:checked + .knobs:before {
  content: "ユーザー画面で非表示中";
  left: 82px;
}

#button-18 .checkbox:checked + .knobs span {
  left: 86px;
  background-color: $color-red;
}

#button-18 .checkbox:checked ~ .layer {
  background-color: rgba($color-red, 0.2);
}
