@import 'variable';

// 文章テンプレート管理
.message-item{
    .inner{
        padding: 40px;
    }
    .select-item{
        width: 70%;
    }
    .message-table-item{
        width: 100%;
        border-top: 1px solid $color-font1-gray;
        border-left: 1px solid $color-font1-gray;
        tr{
            th{
                padding: 30px 10px;
                font-weight: bold;
                background: rgba($color-blue, .03);
                border-bottom: 1px solid $color-font1-gray;
                border-right: 1px solid $color-font1-gray;
            }
            th:nth-child(n+1) {
                width: 25%;
            }
            th:nth-child(n+2){
                width: 55%;
            }
            th:nth-child(n+3){
                width: 20%;
            }
            td{
                padding: 30px 10px;
                border-bottom: 1px solid $color-font1-gray;
                border-right: 1px solid $color-font1-gray;
                font-size: 14px;
                .line-right{
                    border-right: 1px solid $color-gray;
                    padding-right: 20px;
                    margin-right: 10px;
                }
                a{
                    display: inherit;
                }
            }
        }
    }
}

.calendar-tool{
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 80px;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    .calendar-title{
        width: 300px;
    }
}