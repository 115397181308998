.common-box {
  background: white;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 0 8px -2px #ccc;
  .common-text {
    font-family: "Noto Sans";
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.04em;
    color: #0abab5;
    margin-bottom: 20px;
  }
  .field-group {
    display: flex;
    padding: 20px 0;
    border-bottom: solid 1px #ccc;
  }
  .field-group-name {
    width: 300px;
    display: flex;
    align-items: center;
  }
  .field-group-data {
  
    flex: 1;
    &-1 {
      display: flex;
    }
  }
  input[type="checkbox"] {
    display: block;
  }
  .field-inner-left {
    width: 300px;
  }
  .sub-field-group {
    display: flex;
    align-items: center;
    & > :first-child {
      width: 150px;
    }
    & > :last-child {
      flex: 1;
    }
  }
  .field-inner-right {
    flex: 1;
  }
  .field-name {
    font-family: "Noto Sans";
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #555;
  }
  .MuiFormControl-root {
    height: unset;
    background: unset;
  }
  textarea {
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    padding: 0 10px;
    &:focus {
      border: 1px solid #fcdf80;
    }
  }
  .has-datepicker {
    & > :first-child {
      height: 40px;
    }
  }
}
.count-text {
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #aaa;
}
@media (max-width: 1024px) {
  .common-box {
    .field-group-name {
      width: 200px;
    }
    .field-group-data-1 {
      flex-direction: column;
      width: 100%;
    }
    .field-name {
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 767px) {
  .field-group {
    flex-direction: column;
    .field-group-name {
      width: 100%;
    }
  }
}
@media (max-width: 500px) {
  .common-box {
    .sub-field-group {
      flex-direction: column;
      align-items: start;
    }
  }
}
