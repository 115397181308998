@import "variable";

// 文章テンプレート管理
.company-item {
    margin: 40px auto;
    max-width: 1536px;
  .inner {
    padding: 40px;
    margin-top: 0px;
  }
  .select-item {
    width: 70%;
  }
  .company-table-item {
    width: 100%;
    border-top: 1px solid $color-font1-gray;
    border-left: 1px solid $color-font1-gray;
    tr {
      th {
        padding: 30px 10px;
        font-weight: bold;
        background: rgba($color-blue, 0.03);
        border-bottom: 1px solid $color-font1-gray;
        border-right: 1px solid $color-font1-gray;
      }
      th:nth-child(n + 1) {
        width: 30%;
      }
      th:nth-child(n + 2) {
        width: 20%;
      }
      th:nth-child(n + 3) {
        width: 35%;
      }
      th:nth-child(n + 4) {
        width: 15%;
      }
      td {
        padding: 30px 10px;
        border-bottom: 1px solid $color-font1-gray;
        border-right: 1px solid $color-font1-gray;
        font-size: 14px;
        .line-right {
          border-right: 1px solid $color-gray;
          padding-right: 20px;
          margin-right: 10px;
        }
        a {
          display: inherit;
        }
      }
    }
  }
}
