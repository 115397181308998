@import 'variable';

// ログイン＆パスワード変更
.login-item{
  margin-bottom: 30px;
  dt, dd{
    text-align: left;
  }
  dt{
    font-weight: bold;
  }
  .error{
    color: red;
  }
}
.boder-error{
  border: 2px solid red;
  margin-bottom: 40px;
  padding: 10px 0;
  border-radius: 8px;
  .error{
    color: red;
  }
}
.password-item{
  display: flex;
  width: 100%;
  margin: 20px auto 40px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $color-gray;
  text-align: left;  
  dt{
    padding: 8px 20px 8px 0;
    min-width: 250px;
  }
  dd{
    width: inherit;
  }
}
.login-logo{
  margin-bottom: 60px;
  img{
    max-width: 242px;
  }
}