@import 'variable';

// 文章テンプレート管理
.processes-item{
    h1{
        span{
            font-size: 14px;
            color: $color-font1-gray;
            font-weight: 300;
            margin-left: 10px;
        }
    }
    .inner{
        padding: 40px;
    }
    ul{
        border-top: 1px solid $color-gray;
        li{            
            border-bottom: 1px solid $color-gray;
            padding: 20px 0;
            font-size: 16px;
        }
    }
}