@import 'variable';

// 文章テンプレート管理
.report-item{
    .inner {
      margin: 40px auto 50px;
      width: 100%;
      max-width: 100% !important;
      position: relative;
      background: $color-white;
      padding: 40px;
      border-radius: 16px;
      text-align: left;
    }
    .report-header{
        text-align: left;
        h1{
            display: flex;
            .icon-qa {
                img{
                    margin-bottom: 8px;
                }
            }
        }
        .report-tag{
            width: 100%;
            margin-top: 50px;
            font-size: 20px;
            font-weight: bold;
            color: $color-red;
            text-align: right;
        }
    }
    h2{
        font-size: 16px;
        font-weight: bold;
        color: $color-blue;
    }
    .report-flex-item{
        display: flex;
        .report-flex-l{
            width: 77%;
            margin-right: 20px;
        }
        .report-flex-r{
            width: calc(23% - 20px);
            tr{
                border-bottom: 0 !important;
                th{
                    text-align: left;
                }
            }
        }
    }
    .report-graph-item{
        display: flex;
        .report-graph{
            width: calc(30% - 160px);
            max-width: 278px;
            margin-right: 70px;
        }
        .repot-txt{
            width: 35%;
        }
        .repot-txt-margin{
            margin: 0 80px;
        }
    }
    table{
        font-size: 14px;
        width: 100%;
        tr{
            border-bottom: 1px solid $color-gray;
        }
        th{
            font-weight: bold;
            padding: 20px 0;
            text-align: center;
        }
        td{
            padding: 20px 0;
            text-align: center;
        }
        .txt-blod{
            font-weight: bold;
        }
    }
    .tag-item{
        display: flex;
        align-items: center;
    }
    .tag-blue1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: #3F94E1;    
    }
    .tag-red1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-red2;    
    }
    .tag-red2::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-red2, .6);
    }
    .tag-red3::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-red2, .2);
    }
    .tag-purple1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-purple;    
    }
    .tag-purple2::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-purple, .6);
    }
    .tag-purple3::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-purple, .2);
    }
    .tag-blue1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-blue2;    
    }
    .tag-blue2::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-blue2, .6);
    }
    .tag-blue3::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-blue2, .2);
    }
    .tag-blue4::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-blue3;    
    }
    .tag-blue5::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-blue3, .6);
    }
    .tag-blue6::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-blue3, .2);
    }
    .tag-green1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-green;    
    }
    .tag-green2::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-green, .6);
    }
    .tag-green3::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-green, .2);
    }
    .tag-yellow1::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: $color-yellow2;    
    }
    .tag-yellow2::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-yellow2, .6);
    }
    .tag-yellow3::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            border-radius: 2px;
            transform: translateY(-50%);
            background: rgba($color-yellow2, .2);
    }
    .tag{
        margin-right: 20px;
    }
    
    .txt-left{
        text-align: left !important;
        font-weight: bold;
    }
}

//グラフ