@import 'variable';

//common

.bottom-triangle {
  position: relative;
  &:after {
    @include bottom-triangle;
  }
}
// .ant-input{
//   background-color: black;
// }
select {
  -webkit-appearance: none;
  appearance: none; /* デフォルトのスタイルを無効 */
}
select::-ms-expand {
  display: none; /* デフォルトのスタイルを無効(IE用) */
}

input::placeholder,
textarea::placeholder {
  color: $color-font1-gray;
}

.select-wrapper {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: 9px;
    top: 10px;
    width: 0;
    border-bottom: 4.5px solid $color-black;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  &:after {
    content: "";
    position: absolute;
    right: 9px;
    top: 21px;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4.5px solid $color-black;
  }
  select.select {
    padding: 0 10px;
  }
}

//form
form.applicants-detail {
  .applicants-detail-anchor {
    position: relative;
    width: 20%;
    ul {
      position: sticky;
      top: 20px;
      li {
        margin-bottom: 20px;
        padding-bottom: 8px;
        &.current {
          display: inline-block;
          font-weight: bold;
          border-bottom: 2px solid $color-blue;
        }
        a {
          color: $color-black;
        }
      }
    }
  }
  .applicants-detail-right {
    width: 100%;
  }
  .applicants-detail-list {
    ul {
      li {
        padding: 16px 0;
        border-bottom: 1px solid $color-gray;
        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          border-bottom: 0;
        }
        dl {
          display: flex;
          dt {
            width: 20%;
          }
        }
      }
    }
  }
  .applicants-detail-btn {
    justify-content: space-between;
  }
}

dd.dd-inner {
  dl {
    padding: 10px 0;
    border-top: 1px solid $color-gray;
    align-items: baseline;
    justify-content: space-between;
    dd {
      .select-wrapper {
        width: 20%;
        margin-left: auto;
        select.select {
          padding: 0 10px;
        }
      }
      .input-txt {
        width: 78%;
      }
    }
  }
}

.job-new-form {
  .select-wrapper {
    &:before {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4.5px solid $color-black;
    }
  }
  li {
    dl {
      dt {
        margin-top: 8px;
      }
    }
  }
}

//sort-btn
.sort-arrow {
  img {
    width: 25px;
  }
}

//question icon
.q-icon {
  margin-top: 2px;
}
