// topページ

.top-status {
  margin-top: 40px;
  margin-bottom: 20px;
}

ul.summary-select {
  display: flex;
  justify-content: space-between;
  li {
    width: 18%;
    padding: 20px 10px 30px 10px;
    border: 1px solid $color-gray;
    border-radius: 8px;
    .status-count {
      display: block;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      span {
        font-size: 14px;
      }
    }
  }
  select {
    @include progress-select;
  }
}

.top-data {
  margin-bottom: 170px;
  hr {
    height: 1px;
    margin: 20px 0 28px;
    background-color: $color-gray;
    border: none;
  }

  .summary-count {
    margin-top: 68px;
    padding: 12px 20px;
    background: $color-pale-blue;
    border-radius: 4px;
  }
}

// .top-menu {
//   padding: 60px 0 0;
//   justify-content: space-between;
//   .top-menu-item {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-wrap: nowrap;
//     height: 237px;
//     color: $color-white;
//     background: $color-blue;
//     border-radius: 16px;
//   }
//   li {
//     width: 20%;
//   }
// }

.top-message {
  margin: 60px 0;
  padding: 40px;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  ul {
    li {
      padding: 20px 0;
      border-top: 1px solid $color-gray;
      &:last-of-type {
        padding-bottom: 0;
      }
      p {
        font-size: 14px;
        span {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
