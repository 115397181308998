.dialogEdit{
    margin-top:20px;
    text-align: center;
}
.btn-img{
    border-radius: 8px;
    width: 33.33%;
    position: relative;
    margin-top:20px;

    .img{
        width: 220px;
        height:170px;
    }
}
.btn-btn{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.addContent{
    display:flex;
    justify-content:end;
}
.img{
    position: relative;
}
.img-input-file{
    position: absolute;
    top: 0;
    opacity: 0;
}